import React from 'react';
import AppModal from './components/AppModal';
import KejoraButton from 'components/kejora/KejoraButton';

function MoveModal({ onClose, onYes, data, user_name }) {
  return (
    <AppModal onClose={onClose} withClose>
      <div className="space-y-6 text-center">
        <p>
          Move {user_name} to {data.nm}?
        </p>
        <div className="flex gap-2">
          <KejoraButton
            onClick={onClose}
            text="No"
            fullWidth
            size="lg"
            type="secondary"
          />
          <KejoraButton
            onClick={() => onYes(data?.cd, data?.user_nm)}
            text="Yes"
            fullWidth
            size="lg"
            type="primary"
          />
        </div>
      </div>
    </AppModal>
  );
}

export default MoveModal;
