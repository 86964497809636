import React, { useState } from 'react';
import AppModal from './components/AppModal';
import MinusCircledIcon from 'assets/icons/MinusCircledIcon';
import PlusCircledIcon from 'assets/icons/PlusCircledIcon';
import KejoraButton from 'components/kejora/KejoraButton';

function SplitBillModal({ onClose, name, onConfirm, cd, qty, data, desc }) {
  const [quantity, setQuantity] = useState(1);
  const remaining = qty - quantity;
  const addItem = () => {
    if (quantity < qty) {
      setQuantity((prev) => prev + 1);
    }
  };

  const removeItem = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  return (
    <AppModal onClose={onClose} withClose>
      <div>
        <p className="text-sm font-medium">{name}</p>
      </div>
      <div className="font-semibold text-base">{desc}</div>
      <div className="flex justify-evenly items-center gap-4">
        <button onClick={removeItem}>
          <MinusCircledIcon className="w-10 h-10 stroke-app-matte-black" />
        </button>
        <button>{quantity}</button>
        <button onClick={addItem}>
          <PlusCircledIcon className="w-10 h-10 stroke-app-matte-black" />
        </button>
      </div>

      <KejoraButton
        onClick={() =>
          onConfirm(cd, remaining, name, data?.price, quantity, data?.menu_cd)
        }
        text="Confirm"
        fullWidth
        size="lg"
      />
    </AppModal>
  );
}

export default SplitBillModal;
