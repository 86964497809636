import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'

import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { useAuth } from 'utils/context/AuthContext'
import { checkRole } from 'utils/helpers'
import { ROLE_KASIR, ROLE_WAITER } from 'utils/constants';

function MainContainer({
   children
}) {
   const { user } = useAuth()
   const [isMenuOpen, setMenuOpen] = useState(false)
   const userToken = Cookies.get("ut")

   const allowed = user && (checkRole(user?.role_cd, ROLE_KASIR) || checkRole(user?.role_cd, ROLE_WAITER))

   useEffect(() => {
      if ((!userToken || !allowed) && window.location.pathname !== "/login") {
         window.location.assign("/login")
         localStorage.removeItem("un")
      }
   }, [allowed, userToken])


   if ((!userToken || !allowed) && window.location.pathname !== "/login") {
      return <div />
   }
   if (window.location.pathname === "/login") {
      return children
   }
   return (
      <div className='pb-24'>
         <Navbar
            openMenu={() => setMenuOpen(true)}
         />
         {children}
         <Sidebar
            isOpen={isMenuOpen}
            onClose={() => setMenuOpen(false)}
         />
      </div>
   )
}

export default MainContainer