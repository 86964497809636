import React, { useEffect } from 'react'
import moment from 'moment';

import logo from "../assets/images/labewa-logo.png"
import useFetch from 'utils/hooks/useFetch';
import { PRINT_BILL_DETAIL } from 'utils/constants/api'
import { useAuth } from 'utils/context/AuthContext';

const PrintBill = React.forwardRef((props, ref) => {
    const { user } = useAuth()
    const { data: bill, fetch: getBillDetail } = useFetch(PRINT_BILL_DETAIL)
    const _splitBillData = props?.splitBillData?.map(item => ({ ...item, menu_nm: item?.name, total: item.price * item.qty }))

    const billToShow = _splitBillData?.length > 0 ? _splitBillData : bill?.data

    const splitBillTotalPrice = _splitBillData?.reduce((accumulator, currentItem) => {
        return accumulator + (currentItem.price * currentItem.qty);
    }, 0);

    const subtotal = _splitBillData?.length > 0
        ? splitBillTotalPrice
        : bill?.subtotal

    const service = _splitBillData?.length > 0
        ? (splitBillTotalPrice * 0.05)
        : bill?.service

    const pb1 = _splitBillData?.length > 0
        ? (splitBillTotalPrice + (splitBillTotalPrice * 0.05)) * 0.1
        : bill?.pb1

    const total = _splitBillData?.length > 0
        ? splitBillTotalPrice * 1.05 * 1.1
        : bill?.total

    useEffect(() => {
        getBillDetail({
            data: {
                bill_cd: props.bill_cd
            }
        })
    }, [getBillDetail, props.bill_cd])

    return (
        <div ref={ref} className="pr-14 app-print-bill-container flex flex-col text-center">
            <div className="print-bill-main-container">
                <div className="flex justify-center pb-4">
                    <img className="img-size" src={logo} alt="Logo" />
                </div>
                <p className="text-xs">Address: Jl. Satu Maret No.5, RT.5/RW.2, Jakarta Barat</p>
                <div className="flex justify-center py-4">
                    <hr className="border-black border-dashed w-full" />
                </div>

                <div className="pb-4">
                    <p className="text-sm font-medium underline">
                        Receipt {props?.splitBillData?.length > 0 ? "(Split Bill)" : ""}
                    </p>
                    <p className="text-sm">{bill?.table_nm}</p>
                </div>

                <table className="table-fixed text-xs w-full mx-auto">
                    <thead>
                        <tr>
                            <th className="text-center text-xs py-2 border-b border-app-matte-black w-1">QTY</th>
                            <th className="text-left text-xs pl-2 py-2 border-b border-app-matte-black w-6">ITEM</th>
                            <th className="text-right text-xs py-2 border-b border-app-matte-black w-4">PRICE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {billToShow?.map((menuItem, i) => (
                            <tr key={i} className="">
                                <td className="py-1">
                                    {menuItem.qty}
                                </td>
                                <td className="text-left pl-2">
                                    {menuItem.menu_nm}
                                </td>
                                <td className="text-right">
                                    Rp {new Intl.NumberFormat().format(menuItem?.total || 0)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="flex justify-center py-1 pb-1">
                    <hr className="border-black border-dashed w-full" />
                </div>

                <table className="table-fixed w-full">
                    <tr>
                        <td className="w-4/12"></td>
                        <td className="text-xs text-left w-2/12 py-1">Subtotal</td>
                        <td className="text-xs text-right py-1">Rp {new Intl.NumberFormat().format(subtotal || 0)}</td>
                    </tr>
                    <tr>
                        <td className="w-4/12"></td>
                        <td className="text-xs text-left w-2/12 py-1">Service</td>
                        <td className="text-xs text-right ">Rp {new Intl.NumberFormat().format(service || 0)}</td>
                    </tr>
                    <tr>
                        <td className="w-4/12"></td>
                        <td className="text-xs text-left w-2/12 py-1">PB1</td>
                        <td className="text-xs text-right">Rp {new Intl.NumberFormat().format(pb1 || 0)}</td>
                    </tr>
                </table>

                <div className="flex justify-center py-1 pb-1">
                    <hr className="border-black border-dashed w-full" />
                </div>

                <table className="table-fixed w-full">
                    <tr>
                        <td className="w-4/12"></td>
                        <td className="text-xs text-left w-2/12 py-1 font-bold">Total</td>
                        <td className="text-xs text-right py-1 font-bold">Rp {new Intl.NumberFormat().format(total || 0)}</td>
                    </tr>
                </table>

                {/* Footer */}
                <div className="py-6">
                    <div className="flex flex-col justify-center space-x-4">
                        <p className="text-xs">{moment(bill?.time).format('DD-MM-yyyy')}, {moment(bill?.time).format('HH:mm')}</p>
                    </div>
                    <div className="flex justify-center py-1">
                        <p className="text-xs">Serviced by: {user?.name || "Cashier"}</p>
                    </div>
                    <div className="flex justify-center py-2">
                        <p className="text-sm">THANK YOU</p>
                    </div>
                </div>

            </div>


        </div>
    )
})

export default PrintBill