import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { v4 as uuidv4 } from 'uuid';

import EditModal from 'components/modal/EditModal';
import SupervisorModal from 'components/modal/SupervisorModal';
import MenuModal from 'components/modal/MenuModal';
import CartModal from 'components/modal/CartModal';
import SplitBillModal from 'components/modal/SplitBillModal';
import QRModal from 'components/modal/QRModal';
import PrintBillModal from 'components/modal/PrintBillModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';

import food from 'assets/images/food.png';

import useFetch from 'utils/hooks/useFetch';
import {
  CLOSE_BILL,
  GET_BILL,
  GET_ALL_MENU,
  PAID_BILL,
  DELETE_BILL_DETAIL_BULK,
  DELETE_WAITING_LIST,
  EDIT_ORDER,
  ADD_BILL,
  ADD_BILL_DETAIL,
} from 'utils/constants/api';
import {
  calculateService,
  calculateSubtotal,
  calculateTax,
  calculateTotal,
} from 'utils/helpers';
import { useAuth } from 'utils/context/AuthContext';
import { MODAL_INITIAL_STATE, ROLE_KASIR } from 'utils/constants';
import KejoraButton from 'components/kejora/KejoraButton';
import { socket } from 'api';

function TableMain({ table_nm }) {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const {
    data: bill,
    loading: loadingBill,
    fetch: getBillDetail,
  } = useFetch(GET_BILL);
  const { data: menu, fetch: getAllMenu } = useFetch(GET_ALL_MENU);

  const {
    isSubmitting: isSubmittingDeleteDetailBulk,
    fetch: deleteBillDetailBulk,
  } = useFetch(DELETE_BILL_DETAIL_BULK);
  const { fetch: deleteWaitingList } = useFetch(DELETE_WAITING_LIST);
  const { isSubmitting: isSubmittingCloseBill, fetch: closeBill } =
    useFetch(CLOSE_BILL);
  const { isSubmitting: isSubmittingPaidBill, fetch: paidBill } =
    useFetch(PAID_BILL);
  const { fetch: editOrder } = useFetch(EDIT_ORDER);
  const { fetch: addBill } = useFetch(ADD_BILL);
  const { fetch: addBillDetail } = useFetch(ADD_BILL_DETAIL);

  const [editModal, setEditModal] = useState(MODAL_INITIAL_STATE);

  const [supervisorModal, setSupervisorModal] = useState(MODAL_INITIAL_STATE);

  const [menuModal, setMenuModal] = useState(MODAL_INITIAL_STATE);

  const [cartModal, setCartModal] = useState({
    isOpen: false,
    data: [],
  });
  const [qrModal, setQrModal] = useState(MODAL_INITIAL_STATE);

  const [printBillModal, setPrintBillModal] = useState(MODAL_INITIAL_STATE);

  const [confirmationModal, setConfirmationModal] =
    useState(MODAL_INITIAL_STATE);

  const [tab, setTab] = useState('Details');
  const [menuTab, setMenuTab] = useState(0);
  const [cart, setCart] = useState([]);
  const [splitBillMode, setSplitBillMode] = useState(false);
  const [splitBillModal, setSplitBillModal] = useState(MODAL_INITIAL_STATE);
  const [splitBillChecked, setSplitBillChecked] = useState([]);

  const handleCloseBill = () => {
    setConfirmationModal({
      isOpen: true,
      data: {
        onSuccess: async () => {
          await closeBill({
            data: {
              cd: bill?.bill_cd,
              table_cd: bill?.table_cd,
              closed_by: user?.name || 'Cashier',
              user_nm: bill?.user_nm,
            },
          });
          window.location.reload();
        },
      },
    });
  };
  const handlePaidBill = () => {
    setConfirmationModal({
      isOpen: true,
      data: {
        onSuccess: async () => {
          await paidBill({
            data: {
              cd: bill?.bill_cd,
              table_cd: bill?.table_cd,
              paid_by: user?.name || 'Cashier',
              user_nm: bill?.user_nm,
            },
          });
          await deleteWaitingList({
            data: {
              cd: params.id,
            },
          });
          navigate('/');
          window.location.reload();
        },
      },
    });
  };

  const openMenuModal = (item) => {
    document.body.classList.add('modal-open');
    setMenuModal({ isOpen: true, data: item });
  };

  const closeMenuModal = () => {
    document.body.classList.remove('modal-open');
    setMenuModal({ isOpen: false, data: {} });
  };

  const handleAddToCart = (qty, cd, desc) => {
    const _cart = localStorage.getItem('ca');
    const parsedCart = JSON.parse(_cart);
    const bill_cd = bill?.bill_cd;

    if (parsedCart?.[0]?.bill_cd === bill_cd) {
      const newCart = cart.find((item) => item.cd === cd && item?.desc === desc)
        ? cart.map((item) =>
            item?.cd === cd && item?.desc === desc
              ? { ...item, qty: item.qty + qty }
              : { ...item }
          )
        : [...cart, { cd, qty, desc, table_nm }];
      localStorage.setItem('ca', JSON.stringify(newCart));
      closeMenuModal();
      updateLocalCart();
    } else {
      if (parsedCart?.[0]?.bill_cd !== undefined) {
        setConfirmationModal({
          isOpen: true,
          data: {
            title: 'Apakah anda ingin menambahkan pesanan untuk meja ini?',
            body: 'Kami harus mengkosongkan keranjang terlebih dahulu yang berasal dari meja sebelumnya',
            secondaryText: 'Batal',
            primaryText: 'Baik, Kosongkan',
            onSuccess: async () => {
              const newCart = [{ cd, qty, bill_cd, desc, table_nm }];
              localStorage.setItem('ca', JSON.stringify(newCart));
              closeMenuModal();
              updateLocalCart();
              setConfirmationModal((prev) => ({ ...prev, isOpen: false }));
            },
          },
        });
      } else {
        const newCart = [{ cd, qty, bill_cd, desc, table_nm }];
        localStorage.setItem('ca', JSON.stringify(newCart));
        closeMenuModal();
        updateLocalCart();
      }
    }
  };

  const updateLocalCart = useCallback(() => {
    const _cart = localStorage.getItem('ca');
    const parsedCart = JSON.parse(_cart);
    if (parsedCart?.length > 0) {
      let _menu = [];

      menu?.data?.forEach((category) => {
        category?.menu?.forEach((item) => {
          _menu.push(item);
        });
      }, []);

      setCart(parsedCart);
      setCartModal({
        isOpen: true,
        data: parsedCart.map((item) => ({
          ...item,
          ..._menu.find((m) => m.cd === item.cd),
          order_desc: item?.desc,
        })),
      });
    } else {
      setCartModal({ isOpen: false, data: [] });
    }
  }, [menu?.data]);

  const cartTotalItems = cartModal?.data?.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.qty;
  }, 0);

  const cartTotalPrice = cartModal?.data?.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.price * currentItem.qty;
  }, 0);

  const handleConfirmCheck = (cd, remaining, name, price, qty, menu_cd) => {
    setSplitBillChecked((prev) =>
      prev.map((item) => item.cd).includes(cd)
        ? prev.filter((item) => item.cd !== cd)
        : [...prev, { cd, remaining, name, price, qty, menu_cd }]
    );
    setSplitBillModal({ isOpen: false, data: {} });
  };

  const handleChangeSplitBillMode = () => {
    if (splitBillMode) {
      setSplitBillMode(false);
      setSplitBillChecked([]);
    } else {
      setSplitBillMode(true);
    }
  };

  const [loadingPrintBill, setLoadingPrintBill] = useState(false);

  const handlePrintBill = async () => {
    handlePrint();
    setTimeout(() => {
      setConfirmationModal({
        isOpen: true,
        data: {
          title: 'Apakah bill ini sudah sukses tercetak?',
          body: 'Tindakan ini tidak bisa dibatalkan atau diulang',
          primaryText: 'Sudah',
          secondaryText: 'Belum',
          onSuccess: async () => {
            setLoadingPrintBill(true);
            const checked = splitBillChecked.map((item) => ({
              ...item,
              bill_dtl_cd: item.cd,
              split_qty: item.qty,
              qty: item.remaining,
            }));
            if (checked?.length) {
              const bill_cd = uuidv4();
              const split_user_name = `${bill?.user_nm} (Split)`;
              await addBill({
                data: {
                  cd: bill_cd,
                  table_cd: bill?.table_cd,
                  created_by: user?.cd,
                  user_nm: split_user_name,
                },
              });
              await addBillDetail({
                data: {
                  bill_cd,
                  orders: checked.map((item) => ({
                    user_nm: split_user_name,
                    created_by: user?.cd,
                    qty: item.split_qty.toString(),
                    menu_cd: item.menu_cd,
                  })),
                },
              });
              await paidBill({
                data: {
                  cd: bill_cd,
                  table_cd: bill?.table_cd,
                  paid_by: user?.name || 'Cashier',
                  user_nm: split_user_name,
                },
              });
              await deleteBillDetailBulk({
                data: {
                  data: checked,
                },
              });
              setSplitBillChecked([]);
              setLoadingPrintBill(false);
            }
            window.location.reload();
          },
        },
      });
    }, 1000);
  };

  const handleEditOrder = async (bill_dtl_cd, qty) => {
    setSupervisorModal({ isOpen: false, data: {} });
    await editOrder({
      data: {
        bill_dtl_cd,
        qty,
      },
    });
    socket.emit('new-order', {
      bill_dtl_cd,
      qty,
    });
    window.location.reload();
  };

  useEffect(() => {
    socket.on('new-order', (data) =>
      getBillDetail({
        data: {
          table_cd: params.id,
        },
      })
    );
    return () => socket.off('new-order');
  }, [getBillDetail, params.id]);

  useEffect(() => {
    getBillDetail({
      data: {
        table_cd: params.id,
      },
    }).then((res) => {
      getAllMenu({
        data: {
          bill_cd: res?.bill_cd,
        },
      });
    });
  }, [getAllMenu, getBillDetail, params.id]);

  useEffect(() => {
    updateLocalCart();
  }, [updateLocalCart]);

  useEffect(() => {
    window.addEventListener('storage', updateLocalCart);
    return () => {
      window.removeEventListener('storage', updateLocalCart);
    };
  }, [updateLocalCart]);

  const Table = ({ data }) => {
    return (
      <table className="table-auto text-xs sm:text-sm w-full">
        <tbody>
          {data?.map((order, i) => (
            <tr key={i}>
              {splitBillMode && (
                <td className="text-left p-1 sm:px-3 sm:py-2">
                  <input
                    defaultChecked={splitBillChecked
                      .map((item) => item.cd)
                      .includes(order.cd)}
                    type="checkbox"
                    onClick={() =>
                      splitBillChecked.map((item) => item.cd).includes(order.cd)
                        ? setSplitBillChecked((prev) =>
                            prev.filter((item) => item.cd !== order.cd)
                          )
                        : setSplitBillModal({ isOpen: true, data: order })
                    }
                  />
                </td>
              )}
              <td className="text-left p-1 sm:px-3 sm:py-2">{order.qty}</td>
              <td className="text-left p-1 sm:px-3 sm:py-2 whitespace-nowrap">
                <p className="text-xs">{order?.menu_nm}</p>
                {order?.desc && (
                  <p className="text-xs">
                    Notes: <span className="text-app-grey">{order.desc}</span>
                  </p>
                )}
              </td>
              <td className="text-right p-1 sm:px-3 sm:py-2">
                Rp {new Intl.NumberFormat().format(order.qty * order.price)}
              </td>
              <td className="text-right p-1 sm:px-3 sm:py-2">
                {bill?.bill_status !== 'CLOSED' && (
                  <KejoraButton
                    onClick={() => setEditModal({ isOpen: true, data: order })}
                    text=" Edit Order"
                    size="md"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const LoadingTable = () => {
    return (
      <div className="space-y-4">
        {Array.from({ length: 5 }).map((_, i) => (
          <div key={i} className="flex items-center gap-4">
            <div className="h-5 w-5 animate-pulse bg-app-light-grey rounded-md" />
            <div className="h-5 w-32 sm:w-40 animate-pulse bg-app-light-grey rounded-md" />
            <div className="h-5 w-12 sm:w-24 animate-pulse bg-app-light-grey rounded-md" />
            <div className="h-6 w-10 sm:w-20 animate-pulse bg-app-light-grey rounded-md" />
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="flex-1">
      <div className="flex flex-col sm:flex-row items-center sm:items-start justify-between">
        {loadingBill ? (
          <div className="flex justify-center sm:justify-start mt-4 gap-4">
            <div className="h-8 w-16 sm:w-28 animate-pulse bg-app-light-grey rounded-md" />
            <div className="h-8 w-16 sm:w-28 animate-pulse bg-app-light-grey rounded-md" />
            <div className="h-8 w-16 sm:w-28 animate-pulse bg-app-light-grey rounded-md" />
          </div>
        ) : (
          <div className="w-full flex flex-col-reverse sm:flex-row justify-between mt-4 gap-4">
            <div className="flex sm:justify-start space-x-2 sm:space-x-4 overflow-x-auto -mx-4 px-4 sm:mx-0 sm:px-0 py-2">
              {['Details', 'Order List', 'Add Order']?.map((_tab, i) => (
                <KejoraButton
                  className="whitespace-nowrap"
                  key={_tab}
                  onClick={() => setTab(_tab)}
                  text={_tab}
                  type={tab === _tab ? 'primary' : 'secondary'}
                />
              ))}
            </div>
            <KejoraButton
              className="whitespace-nowrap"
              key="QR Code"
              onClick={() =>
                setQrModal({ isOpen: true, data: { table_nm: bill?.table_nm } })
              }
              text="QR Code"
            />
          </div>
        )}
      </div>
      {tab === 'Details' && (
        <div className="py-8 sm:py-12 space-y-4">
          <section className="space-y-6">
            <div className="flex space-x-2">
              <p className="font-bold">Time Left:</p>
              <p>01:00:00</p>
            </div>
            <div>
              <KejoraButton
                type="secondary"
                onClick={() => {}}
                text={'Close'}
              />
            </div>
          </section>
        </div>
      )}
      {tab === 'Order List' && (
        <div className="py-8 sm:py-12 space-y-4">
          <section className="space-y-6">
            <div className="flex flex-col sm:flex-row justify-between gap-8 sm:gap-4">
              <div className="lg:hidden">
                {loadingBill ? (
                  <LoadingTable />
                ) : bill?.data?.length > 0 ? (
                  <Table data={bill?.data} />
                ) : (
                  <div className="flex items-center justify-center text-center flex-1 text-sm sm:text-base font-semibold">
                    Empty order list
                  </div>
                )}
              </div>
              <div className="hidden lg:flex flex-1 gap-4">
                {loadingBill ? (
                  <>
                    <LoadingTable />
                    <LoadingTable />
                  </>
                ) : bill?.data?.length > 0 ? (
                  <>
                    <div>
                      <Table
                        data={bill?.data.filter((order, i) => i % 2 === 0)}
                      />
                    </div>
                    <div>
                      <Table
                        data={bill?.data.filter((order, i) => i % 2 !== 0)}
                      />
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center text-center flex-1 text-sm sm:text-base font-semibold">
                    Empty order list
                  </div>
                )}
              </div>
              <div className="flex justify-center">
                {loadingBill ? (
                  <div className="flex flex-col gap-12 border border-app-light-grey rounded-xl p-5">
                    <div className="grid grid-cols-2 gap-6">
                      <div className="h-5 w-16 animate-pulse bg-app-light-grey rounded-md" />
                      <div className="h-5 w-20 animate-pulse bg-app-light-grey rounded-md" />
                      <div className="h-5 w-12 animate-pulse bg-app-light-grey rounded-md" />
                      <div className="h-5 w-20 animate-pulse bg-app-light-grey rounded-md" />
                      <div className="h-5 w-8 animate-pulse bg-app-light-grey rounded-md" />
                      <div className="h-5 w-20 animate-pulse bg-app-light-grey rounded-md" />
                      <div className="h-5 w-12 animate-pulse bg-app-light-grey rounded-md" />
                      <div className="h-5 w-20 animate-pulse bg-app-light-grey rounded-md" />
                    </div>
                    {user?.role_cd === ROLE_KASIR && (
                      <div className="flex flex-col gap-4">
                        <div className="h-8 w-full animate-pulse bg-app-light-grey rounded-md" />
                        <div className="h-8 w-full animate-pulse bg-app-light-grey rounded-md" />
                        <div className="h-8 w-full animate-pulse bg-app-light-grey rounded-md" />
                        <div className="h-8 w-full animate-pulse bg-app-light-grey rounded-md" />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="w-full sm:w-fit flex flex-col gap-6 sm:gap-12 border border-app-light-grey rounded-xl p-5">
                    <div className="grid grid-cols-2 gap-6 text-sm sm:text-base">
                      <div className="">Subtotal</div>
                      <div className="whitespace-nowrap text-right">
                        {calculateSubtotal(bill?.data)}
                      </div>
                      <div className="">Servis</div>
                      <div className="whitespace-nowrap text-right">
                        {calculateService(bill?.data)}
                      </div>
                      <div className="">PB1</div>
                      <div className="whitespace-nowrap text-right">
                        {calculateTax(bill?.data)}
                      </div>
                      <div className="font-bold">Total</div>
                      <div className="font-bold whitespace-nowrap text-right">
                        {calculateTotal(bill?.data)}
                      </div>
                    </div>
                    {user?.role_cd === ROLE_KASIR && (
                      <div className="flex flex-col gap-4 text-sm sm:text-base">
                        {bill?.bill_status !== 'CLOSED' && (
                          <KejoraButton
                            onClick={handleCloseBill}
                            text="Close Bill"
                          />
                        )}
                        <KejoraButton
                          onClick={handleChangeSplitBillMode}
                          text={
                            splitBillMode ? 'Cancel Split Bill' : 'Split Bill'
                          }
                        />
                        <KejoraButton
                          disabled={
                            splitBillMode && splitBillChecked?.length <= 0
                          }
                          onClick={() => setPrintBillModal({ isOpen: true })}
                          text="Print Bill"
                        />

                        {bill?.bill_status === 'CLOSED' && (
                          <KejoraButton
                            disabled={
                              splitBillMode && splitBillChecked?.length <= 0
                            }
                            onClick={handlePaidBill}
                            text="Paid"
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      )}
      {tab === 'Add Order' && (
        <div className="mt-8">
          <div className="flex gap-2 overflow-x-auto p-2 -mx-2 w-full">
            {menu?.data?.map((category, i) => (
              <button
                onClick={() => setMenuTab(i)}
                key={i}
                className={`min-w-fit flex items-center space-x-1 p-1 rounded-[4px] border ${
                  menuTab === i
                    ? 'border-app-matte-black'
                    : 'border-app-light-grey'
                }`}
              >
                <img
                  className="h-8 w-8 rounded-sm object-cover"
                  src={category?.img || food}
                  alt={category.nm}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = food;
                  }}
                />
                <p className="text-sm">{category.name}</p>
              </button>
            ))}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
            {menu?.data?.[menuTab]?.menu?.map((item, i) => {
              return (
                <div key={i} className="flex-row">
                  <div
                    key={i}
                    className="flex items-center space-x-1 p-2 rounded-[4px] border"
                  >
                    <img
                      className="w-24 aspect-square rounded-l-md object-cover"
                      src={item?.img}
                      alt={item.nm}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = `/images/Food_Placeholder.png`;
                      }}
                    />
                    <div className="flex-1 flex flex-col justify-between p-2 gap-8 rounded-r-md text-app-matte-black">
                      <p className="text-sm">{item.nm}</p>
                      <p className="text-right text-[10px]">
                        Rp {new Intl.NumberFormat().format(item.price)}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center justify-center space-x-1 p-2 rounded-[4px] border">
                    <KejoraButton
                      onClick={() => openMenuModal(item)}
                      text="Add Order"
                      fullWidth
                      size="lg"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {editModal.isOpen && (
        <EditModal
          onClose={() => setEditModal({ isOpen: false })}
          image_url=""
          name={editModal?.data?.menu_nm}
          cd={editModal?.data?.cd}
          initialQty={editModal?.data?.qty}
          onEditOrder={(cd, qty) => {
            setEditModal((prev) => ({ data: { cd, qty }, isOpen: false }));
            setSupervisorModal({ isOpen: true });
          }}
        />
      )}
      {supervisorModal.isOpen && (
        <SupervisorModal
          onClose={() => setSupervisorModal({ isOpen: false })}
          onSuccess={() =>
            handleEditOrder(editModal?.data?.cd, editModal?.data?.qty)
          }
        />
      )}
      {menuModal?.isOpen && (
        <MenuModal
          onClose={closeMenuModal}
          image_url={menuModal?.data?.img}
          cd={menuModal.data.cd}
          name={menuModal.data.nm}
          price={menuModal.data.price}
          onAddToCart={handleAddToCart}
        />
      )}

      {splitBillModal?.isOpen && (
        <SplitBillModal
          onClose={() => setSplitBillModal({ isOpen: false })}
          name={splitBillModal?.data?.menu_nm}
          cd={splitBillModal?.data?.cd}
          desc={menuModal.data.desc}
          qty={splitBillModal?.data?.qty}
          data={splitBillModal?.data}
          onConfirm={handleConfirmCheck}
        />
      )}

      {qrModal.isOpen && (
        <QRModal
          data={qrModal?.data}
          onClose={() => setQrModal({ isOpen: false, data: {} })}
          initialQRCode={bill?.bill_cd}
        />
      )}

      {printBillModal.isOpen && (
        <PrintBillModal
          onClose={() => setPrintBillModal({ isOpen: false })}
          printRef={componentRef}
          handlePrint={handlePrintBill}
          bill_cd={bill?.bill_cd}
          splitBillData={splitBillChecked}
        />
      )}
      {confirmationModal.isOpen && (
        <ConfirmationModal
          onClose={() => setConfirmationModal({ isOpen: false, data: {} })}
          data={confirmationModal?.data}
          isSubmitting={
            isSubmittingCloseBill ||
            isSubmittingPaidBill ||
            isSubmittingDeleteDetailBulk ||
            loadingPrintBill
          }
        />
      )}
      {cartModal?.isOpen && (
        <CartModal
          totalItems={cartTotalItems}
          totalPrice={cartTotalPrice}
          table_cd={params.id}
          table_nm={cartModal?.data?.[0]?.table_nm}
        />
      )}
    </div>
  );
}

export default TableMain;
