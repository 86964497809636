import React, { useEffect, useRef, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import useFetch from 'utils/hooks/useFetch';
import { GET_BILL_SUMMARY } from 'utils/constants/api';
import { useReactToPrint } from 'react-to-print';
import AppModal from 'components/modal/components/AppModal';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import { useAuth } from 'utils/context/AuthContext';
import { MODAL_INITIAL_STATE, ROLE_KASIR } from 'utils/constants';
import KejoraButton from 'components/kejora/KejoraButton';

function BillHistory() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    data: billSummary,
    loading: loadingBillSummary,
    fetch: getBillSummary,
  } = useFetch(GET_BILL_SUMMARY);

  const [_fromDate, setFromDate] = useState(null);
  const [_toDate, setToDate] = useState(null);

  const fromDate = localStorage.getItem('fd') || _fromDate || '';
  const toDate = localStorage.getItem('td') || _toDate || '';

  const handleSetFromDate = (value) => {
    if (value) {
      setFromDate(value);
      localStorage.setItem('fd', value);
    } else {
      setFromDate('');
      localStorage.removeItem('fd');
    }
  };
  const handleSetToDate = (value) => {
    if (value) {
      setToDate(value);
      localStorage.setItem('td', value);
    } else {
      setToDate('');
      localStorage.removeItem('td');
    }
  };

  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const [printModal, setPrintModal] = useState(MODAL_INITIAL_STATE);

  function handleReset() {
    handleSetFromDate(null);
    handleSetToDate(null);
    window.location.reload();
  }

  useEffect(() => {
    getBillSummary({
      data: {
        from_dt: parseInt(moment(fromDate).format('X')),
        to_dt: parseInt(moment(toDate).format('X')),
      },
    });
  }, [fromDate, getBillSummary, toDate]);

  useEffect(() => {
    const localStorageFromDate = localStorage.getItem('fd');
    const localStorageToDate = localStorage.getItem('td');

    if (!localStorageFromDate || !localStorageToDate) {
      const nowStart = moment();
      const startHour = 9;
      const startDate = nowStart.hour(startHour).minute(0).second(0);

      const nowEnd = moment();
      const endHour = 23;
      const endDate = nowEnd.hour(endHour).minute(59).second(59);

      if (moment().isAfter(startDate) && moment().isBefore(endDate)) {
        handleSetFromDate(moment(new Date().setHours(9, 0)).format());
        handleSetToDate(
          moment(new Date().setHours(3, 0)).add(1, 'days').format()
        );
      } else {
        handleSetFromDate(
          moment(new Date().setHours(9, 0)).subtract(1, 'days').format()
        );
        handleSetToDate(moment(new Date().setHours(3, 0)).format());
      }
    }
  }, []);

  useEffect(() => {
    if (user?.role_cd !== ROLE_KASIR) {
      window.location.replace('/');
    }
  }, [user?.role_cd]);

  return (
    <div className="app-main-container space-y-8">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <button onClick={() => navigate(-1)}>
            <ArrowLeftIcon />
          </button>
          <p className="font-semibold text-xl">Bill History</p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row justify-end gap-4">
        <div className="flex gap-2 items-center justify-between lg:justify-start">
          <p>From</p>
          <DateTimePicker
            format="dd/MM/y HH:mm"
            disableClock
            onChange={handleSetFromDate}
            value={fromDate}
          />
        </div>
        <div className="flex gap-2 items-center justify-between lg:justify-start">
          <p>To</p>
          <DateTimePicker
            format="dd/MM/y HH:mm"
            disableClock
            onChange={handleSetToDate}
            value={toDate}
          />
        </div>
        <KejoraButton type="secondary" onClick={handleReset} text="Reset" />
        <KejoraButton
          disabled={loadingBillSummary}
          type="secondary"
          onClick={() => setPrintModal({ isOpen: true, data: {} })}
          text="Generate Summary"
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {loadingBillSummary
          ? Array.from({ length: 12 }).map((_, i) => (
              <div
                key={i}
                className="flex flex-col justify-between border border-app-light-grey px-5 py-4 rounded-lg space-y-4"
              >
                <div className="flex justify-between items-start gap-4">
                  <div className="space-y-2">
                    <div className="animate-pulse bg-app-light-grey rounded-md w-44 h-5" />
                    <div className="animate-pulse bg-app-light-grey rounded-md w-36 h-5" />
                  </div>
                  <div className="animate-pulse bg-app-light-grey rounded-md w-6 h-6" />
                </div>
                <div className="animate-pulse bg-app-light-grey rounded-md w-52 h-5" />
              </div>
            ))
          : billSummary?.data?.map((item, i) => (
              <div
                key={i}
                className="flex flex-col justify-between border border-app-light-grey px-5 py-4 rounded-lg space-y-4"
              >
                <div className="flex justify-between items-start gap-4">
                  <p className="text-lg font-semibold">{item?.table_nm}</p>
                  <button
                    onClick={() => navigate(`/bill-history/${item?.bill_cd}`)}
                    className="p-1 active:outline transition-all"
                  >
                    <ArrowRightIcon className="h-6 w-6 fill-app-matte-black" />
                  </button>
                </div>
                <div className="flex justify-between gap-4">
                  <p>{moment(item?.bill_dt).format('DD/MM/YYYY HH:mm')}</p>
                </div>
              </div>
            ))}
      </div>
      {printModal?.isOpen && (
        <AppModal
          withClose
          onClose={() => setPrintModal({ isOpen: false, data: {} })}
        >
          <div className="flex-1 flex flex-col items-center justify-center space-y-4 overflow-hidden">
            <div className="flex-1 overflow-auto -mr-20">
              <div
                ref={printRef}
                className="pr-14 app-print-bill-container flex flex-col text-center items-center"
              >
                <div>
                  <div className="pb-4 space-y-2">
                    <p className="font-bold">Summary</p>
                    <p className="text-xs">
                      {moment(fromDate).format('DD/MM HH:mm')} -{' '}
                      {moment(toDate).format('DD/MM HH:mm')}
                    </p>
                  </div>
                  <table className="table-fixed text-xs w-full mx-auto">
                    <thead>
                      <tr>
                        <th className="text-left text-xs py-2 pr-3 border-b border-app-matte-black w-1">
                          Date
                        </th>
                        <th className="text-left text-xs py-2 pr-2 border-b border-app-matte-black w-6">
                          Name
                        </th>
                        <th className="text-right text-xs py-2 border-b border-app-matte-black w-4">
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {billSummary?.data.map((item, i) => (
                        <tr key={i}>
                          <td className="text-left py-1 pr-3">
                            {moment(item.bill_dt).format('DD/MM HH:mm')}
                          </td>
                          <td className="text-left py-1 pr-2">
                            {item.table_nm}
                          </td>
                          <td className="text-right py-1">
                            Rp {new Intl.NumberFormat().format(item.total || 0)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="flex justify-center py-1 pb-1">
                    <hr className="border-black border-dashed w-full" />
                  </div>
                  <table className="table-fixed w-full">
                    <tr>
                      <td className="w-4/12"></td>
                      <td className="text-xs text-left w-2/12 py-1">
                        Subtotal
                      </td>
                      <td className="text-xs text-right py-1">
                        Rp{' '}
                        {new Intl.NumberFormat().format(
                          billSummary?.subtotal || 0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-4/12"></td>
                      <td className="text-xs text-left w-2/12 py-1">Service</td>
                      <td className="text-xs text-right ">
                        Rp{' '}
                        {new Intl.NumberFormat().format(
                          billSummary?.service || 0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-4/12"></td>
                      <td className="text-xs text-left w-2/12 py-1">PB1</td>
                      <td className="text-xs text-right">
                        Rp{' '}
                        {new Intl.NumberFormat().format(billSummary?.pb1 || 0)}
                      </td>
                    </tr>
                  </table>
                  <div className="flex justify-center py-1 pb-1">
                    <hr className="border-black border-dashed w-full" />
                  </div>
                  <table className="table-fixed w-full">
                    <tr>
                      <td className="w-2/12"></td>
                      <td className="text-xs text-left w-2/12 py-1 font-bold">
                        Total
                      </td>
                      <td className="text-xs text-right py-1 font-bold w-8/12">
                        Rp{' '}
                        {new Intl.NumberFormat().format(
                          billSummary?.total || 0
                        )}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <KejoraButton
              onClick={handlePrint}
              text="Print"
              fullWidth
              size="lg"
            />
          </div>
        </AppModal>
      )}
    </div>
  );
}

export default BillHistory;
