export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const CUSTOMER_SITE_URL = process.env.REACT_APP_CUSTOMER_URL;

// Bill

export const ADD_BILL = {
  method: 'post',
  url: '/bill/create',
};

export const UPDATE_BILL = {
  method: 'post',
  url: '/bill/update',
};

export const CLOSE_BILL = {
  method: 'post',
  url: '/bill/close',
};

export const PAID_BILL = {
  method: 'post',
  url: '/bill/paid',
};

export const GET_BILL_SUMMARY = {
  method: 'post',
  url: '/bill/summary',
};

// Bill Detail

export const GET_BILL = {
  method: 'post',
  url: '/bill-dtl/get',
};

export const GET_BILL_DETAIL_BY_CD = {
  method: 'post',
  url: '/bill-dtl/get-by-cd',
};

export const GET_ALL_MENU = {
  method: 'post',
  url: '/menu/get-all',
};
export const ADD_MENU = {
  method: 'post',
  url: '/menu/create',
};
export const ADD_BILL_DETAIL = {
  method: 'post',
  url: '/bill-dtl/create',
};
export const UPDATE_BILL_DETAIL = {
  method: 'post',
  url: '/bill-dtl/update',
};

export const PRINT_BILL_DETAIL = {
  method: 'post',
  url: '/bill-dtl/print',
};

export const DELETE_BILL_DETAIL_BULK = {
  method: 'post',
  url: '/bill-dtl/bulk-create',
};

export const GET_TABLE = {
  method: 'post',
  url: '/table/get',
};

export const GET_ALL_WAITING_LIST = {
  method: 'post',
  url: '/waiting-list/get',
};
export const GET_WAITING_LIST_DETAIL = {
  method: 'post',
  url: '/waiting-list/get',
};
export const DELETE_WAITING_LIST = {
  method: 'post',
  url: '/waiting-list/delete',
};
export const ADD_WAITING_LIST = {
  method: 'post',
  url: '/waiting-list/create',
};
export const GET_ALL_ORDER_KITCHEN = {
  method: 'post',
  url: '/waiting-list/delete',
};

export const ADD_BILL_SUMMARY = {
  method: 'post',
  url: '/waiting-list/create',
};

export const GET_BILL_SUMMARY_DTL = {
  method: 'get',
  url: '/bill/summary',
};
export const GET_ALL_CATEGORY = {
  method: 'post',
  url: '/category/get',
};
export const EDIT_ORDER = {
  method: 'post',
  url: '/bill-dtl/update-qty',
};

export const CHECK_USER_CREDENTIAL = {
  method: 'post',
  url: '/auth/login',
};
export const CHECK_USER_CREDENTIAL_SUPERVISOR = {
  method: 'post',
  url: '/check-user-credential-supervisor',
};

export const GET_ALL_USERS = {
  method: 'post',
  url: '/user/get-all',
};

export const GET_USERS_BY_ROLE = {
  method: 'post',
  url: '/user/get-by-role',
};

export const ADD_TABLE_SESSION_OPEN = {
  method: 'post',
  url: '/table-session/open',
};

export const ADD_TABLE_SESSION_FIXED = {
  method: 'post',
  url: '/table-session/fixed',
};

export const CLOSE_TABLE_SESSION = {
  method: 'post',
  url: '/table-session/close',
};
