import React from 'react';
import AppModal from './components/AppModal';
import KejoraButton from 'components/kejora/KejoraButton';

function ConfirmationModal({ data, isSubmitting, onClose }) {
  return (
    <AppModal>
      <div className="flex flex-col items-center gap-8">
        <div className="flex flex-col items-center text-center gap-2">
          <p className="font-bold text-lg">
            {data?.title || 'Apakah anda yakin?'}
          </p>
          <p className="text-base">
            {data?.body || 'Tindakan ini tidak bisa dibatalkan'}
          </p>
        </div>
        <div className="w-full flex gap-2">
          <KejoraButton
            disabled={isSubmitting}
            onClick={onClose}
            text={data?.secondaryText || 'Cancel'}
            fullWidth
            size="md"
            type="secondary"
          />
          <KejoraButton
            disabled={isSubmitting}
            onClick={data?.onSuccess}
            text={data?.primaryText || 'Yakin'}
            fullWidth
            size="md"
            type="primary"
          />
        </div>
      </div>
    </AppModal>
  );
}

export default ConfirmationModal;
