import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'react-qr-code';
import { v4 as uuidv4 } from 'uuid';

import { CUSTOMER_SITE_URL, ADD_BILL } from 'utils/constants/api';
import useFetch from 'utils/hooks/useFetch';
import AppModal from './components/AppModal';
import { useAuth } from 'utils/context/AuthContext';
import KejoraButton from 'components/kejora/KejoraButton';

function QRModal({ onClose, data, initialQRCode }) {
  const { user } = useAuth();

  const { fetch: addBill } = useFetch(ADD_BILL);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleClose = () => {
    onClose();
    window.location.reload();
  };
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [qrCode, setQrCode] = useState('');

  const QRToPrint = React.forwardRef((props, ref) => {
    return (
      <div
        ref={ref}
        className="flex flex-col gap-8 items-center w-min mx-auto justify-center pr-14"
      >
        <div className="space-y-4 flex flex-col items-center">
          <p className="font-semibold text-center">{props?.table_nm}</p>
          <QRCode size={128} value={`${CUSTOMER_SITE_URL}?code=${qrCode}`} />
        </div>
        <p className="text-xs text-center">
          {`${CUSTOMER_SITE_URL}?code=${qrCode}`}
        </p>
      </div>
    );
  });

  const handleConfirm = async () => {
    if (name) {
      setPage(2);
      await addBill({
        data: {
          cd: qrCode,
          table_cd: data?.table_cd,
          created_by: user?.cd,
          user_nm: name,
        },
      });
    }
  };

  useEffect(() => {
    setQrCode(initialQRCode ? initialQRCode : uuidv4());
  }, [initialQRCode]);

  if (initialQRCode) {
    return (
      <AppModal onClose={handleClose} withClose>
        <div className="space-y-4">
          <div className="-mr-14">
            <QRToPrint ref={componentRef} table_nm={data?.table_nm} />
          </div>
          <KejoraButton
            onClick={handlePrint}
            size="lg"
            fullWidth
            text="Print QR Code"
          />
        </div>
      </AppModal>
    );
  }
  return (
    <AppModal onClose={handleClose} withClose>
      {page === 2 && name ? (
        <div className="space-y-4">
          <div className="-mr-14">
            <QRToPrint ref={componentRef} table_nm={data?.table_nm} />
          </div>
          <KejoraButton
            onClick={handlePrint}
            size="lg"
            fullWidth
            text="Print QR Code"
          />
        </div>
      ) : (
        <div className="space-y-4">
          <input
            className="rounded-md border border-app-matte-black p-4 w-full"
            placeholder="Input name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <KejoraButton
            onClick={handleConfirm}
            size="lg"
            fullWidth
            text="Confirm"
          />
        </div>
      )}
    </AppModal>
  );
}

export default QRModal;
