import EllipseIcon from 'assets/icons/EllipseIcon'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function CartModal({
    totalPrice,
    totalItems,
    table_cd,
    table_nm,
}) {
    const navigate = useNavigate()

    return (
        <div className="fixed bottom-0 left-0 right-0 flex items-center justify-center z-30 p-4 bg-white text-xs sm:text-sm md:text-base">
            <div onClick={() => navigate(`/add-order/${table_cd}/cart`)} className="flex flex-col bg-app-green rounded-md w-full text-white cursor-pointer px-4 py-2">
                <div className="block sm:hidden text-center mb-2 sm:mb-0">{table_nm}</div>
                <div className="flex justify-between">
                    <div className="flex justify-between items-center gap-4">
                        <div className="hidden sm:block">{table_nm}</div>
                        <div className="hidden sm:block"><EllipseIcon /></div>
                        <div className="font-semibold">Cart</div>
                        <div><EllipseIcon /></div>
                        <div>{totalItems} item{totalItems > 1 ? "s" : ''}</div>
                    </div>
                    <div className="font-semibold pl-8">Rp {new Intl.NumberFormat().format(totalPrice)}</div>
                </div>
            </div>
        </div>
    )
}

export default CartModal