import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import TableMain from 'components/table-page/TableMain';
import MoveModal from 'components/modal/MoveModal';

import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';

import {
  DELETE_WAITING_LIST,
  GET_TABLE,
  GET_WAITING_LIST_DETAIL,
  UPDATE_BILL,
  GET_BILL,
} from 'utils/constants/api';
import useFetch from 'utils/hooks/useFetch';
import KejoraButton from 'components/kejora/KejoraButton';
import KejoraStatus from 'components/kejora/KejoraStatus';

function WaitingDetails() {
  const params = useParams();
  const navigate = useNavigate();

  const { data: tables, fetch: getAllTable } = useFetch(GET_TABLE);
  const { fetch: deleteWaitingList } = useFetch(DELETE_WAITING_LIST);
  const { fetch: updateBill } = useFetch(UPDATE_BILL);
  const { data: detail, fetch: getDetail } = useFetch(GET_WAITING_LIST_DETAIL);
  const { data: bill, fetch: getBillDetail } = useFetch(GET_BILL);

  const [moveModal, setMoveModal] = useState({ isOpen: false, data: {} });

  useEffect(() => {
    getAllTable({
      data: {},
    });
  }, [getAllTable]);

  useEffect(() => {
    getDetail({
      data: {
        cd: params.id,
      },
    });
  }, [getAllTable, getDetail, params.id]);

  useEffect(() => {
    getBillDetail({
      data: {
        table_cd: params.id,
      },
    });
  }, [getBillDetail, params.id]);

  const handleMove = async (table_cd) => {
    await deleteWaitingList({
      data: {
        cd: params.id,
      },
    });
    await updateBill({
      data: {
        cd: detail?.bill_cd,
        table_cd: table_cd,
        moved_by: 'Cashier',
        user_nm: detail?.data?.nm,
      },
    });

    setMoveModal({ isOpen: false });
    navigate('/');
  };

  return (
    <div>
      <section>
        <div className="app-main-container space-y-4 sm:space-y-8">
          <div className="flex items-center justify-between space-x-5">
            <div className="flex items-center space-x-5">
              <button onClick={() => navigate('/')}>
                <ArrowLeftIcon className="w-4 h-4 fill-app-matte-black" />
              </button>
              <p className="font-medium text-base sm:text-xl md:text-2xl">
                Waiting List {detail?.data?.number} - {detail?.data?.nm}
              </p>
            </div>
            {bill?.bill_status && (
              <div className="whitespace-nowrap">
                <KejoraStatus text={bill?.bill_status} size="lg" />
              </div>
            )}
          </div>
          <p className="font-semibold text-lg sm:text-xl md:text-2xl">
            Available Table
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-5">
            {false
              ? Array.from({ length: 12 }).map((_, i) => (
                  <div
                    key={i}
                    className="flex flex-col gap-8 border border-app-light-grey rounded-md p-5"
                  >
                    <div className="h-6 w-12 sm:w-24 animate-pulse bg-app-light-grey rounded-md" />
                    <div className="h-8 w-full animate-pulse bg-app-light-grey rounded-md" />
                  </div>
                ))
              : tables?.data
                  ?.filter((table) => table.status === 'EMPTY')
                  .map((table, i) => (
                    <div
                      key={i}
                      className="flex flex-col gap-4 sm:gap-8 border border-app-light-grey rounded-md p-3 sm:p-5"
                    >
                      <div className="flex justify-between">
                        <p className="text-sm sm:text-base font-semibold">
                          {table.nm}
                        </p>
                      </div>
                      <KejoraButton
                        text="Move"
                        onClick={() =>
                          setMoveModal({ isOpen: true, data: table })
                        }
                        disabled={bill?.bill_status === 'CLOSED'}
                      />
                    </div>
                  ))}
          </div>
          <TableMain
            table_nm={`Waiting List ${detail?.data?.number} - ${detail?.data?.nm}`}
          />
        </div>
      </section>

      {moveModal.isOpen && (
        <MoveModal
          onClose={() => setMoveModal({ isOpen: false, data: {} })}
          onYes={handleMove}
          data={moveModal.data}
          user_name={detail?.data?.nm}
        />
      )}
    </div>
  );
}

export default WaitingDetails;
