import ChangeAccount from 'pages/ChangeAccount';
import Home from 'pages/Home';
import Login from 'pages/Login';
import OrderList from 'pages/OrderList';
import TableDetails from 'pages/TableDetails';
import WaitingDetails from 'pages/WaitingDetails';
import AddOrderCart from 'pages/AddOrderCart';
import PrintBill from 'pages/print-bill';
import BillHistory from 'pages/BillHistory';
import SummaryDetails from 'pages/SummaryDetails';
import Summary from 'pages/Summary';
import PrintSummary from 'pages/PrintSummary';
import BillDetails from 'pages/BillDetails';

export const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/bill-history',
    element: <BillHistory />,
  },
  {
    path: '/bill-history/:id',
    element: <BillDetails />,
  },
  {
    path: '/table/:id',
    element: <TableDetails />,
  },
  {
    path: '/waiting/:id',
    element: <WaitingDetails />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/order-list',
    element: <OrderList />,
  },
  {
    path: '/add-order/:id/cart',
    element: <AddOrderCart />,
  },
  // {
  //   path: '/change-account',
  //   element: <ChangeAccount />,
  // },
  // {
  //   path: '/print-bill',
  //   element: <PrintBill />,
  // },
  // {
  //   path: '/summary',
  //   element: <Summary />,
  // },
  // {
  //   path: '/summary/:id',
  //   element: <SummaryDetails />,
  // },
  // {
  //   path: '/print-summary',
  //   element: <PrintSummary />,
  // },
];
export const ROLE_ADMIN = 'admin';
export const ROLE_OWNER = 'owner';
export const ROLE_KITCHEN = 'kitchen';
export const ROLE_BARISTA = 'barista';
export const ROLE_KASIR = 'kasir';
export const ROLE_WAITER = 'waiter';

export const MODAL_INITIAL_STATE = {
  isOpen: false,
  data: {},
};
