import React, { useState } from 'react';
import MinusCircledIcon from 'assets/icons/MinusCircledIcon';
import PlusCircledIcon from 'assets/icons/PlusCircledIcon';
import foodHD from 'assets/images/food.png';
import AppModal from './components/AppModal';
import KejoraButton from 'components/kejora/KejoraButton';

function MenuModal({ onClose, cd, name, price, onAddToCart, image_url }) {
  const [quantity, setQuantity] = useState(1);
  const [desc, setDesc] = useState('');

  const addItem = () => {
    setQuantity((prev) => prev + 1);
  };

  const removeItem = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  const handleAddToCart = () => {
    onAddToCart(quantity, cd, desc);
  };

  return (
    <AppModal withClose onClose={onClose}>
      <div>
        <img
          className="aspect-square w-2/3 mx-auto border border-app-matte-black object-contain"
          src={image_url || foodHD}
          alt="food"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `/images/Food_Placeholder.png`;
          }}
        />
      </div>
      <div>
        <p className="text-sm sm:text-base font-bold">{name}</p>
      </div>
      <div className="flex flex-col space-y-2 text-sm">
        <label className="font-medium" htmlFor="notes">
          Notes
        </label>
        <input
          id="notes"
          className="px-3 py-2 border rounded-lg"
          placeholder="Enter your notes here"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        />
      </div>
      <div className="flex justify-between text-sm">
        <div className="flex items-center gap-4">
          <button onClick={removeItem}>
            <MinusCircledIcon />
          </button>
          <button>{quantity}</button>
          <button onClick={addItem}>
            <PlusCircledIcon />
          </button>
        </div>
        <div className="font-semibold text-base">
          Rp {new Intl.NumberFormat().format(price)}
        </div>
      </div>
      <KejoraButton
        onClick={handleAddToCart}
        text="Add to cart"
        fullWidth
        size="lg"
      />
    </AppModal>
  );
}

export default MenuModal;
