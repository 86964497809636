import moment from 'moment';

export const SERVICE_FEE = 0.05;
export const TAX_FEE = 0.1;
export const SERVICE_FEE_MULTIPLIER = 1.05;
export const TAX_FEE_MULTIPLIER = 1.1;

export const formatPriceNumber = (number) => {
  return `Rp ${new Intl.NumberFormat().format(number || 0)}`;
};

export const subtotal = (items) =>
  items?.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.price * currentItem.qty;
  }, 0);

export const calculateSubtotal = (items) => formatPriceNumber(subtotal(items));
export const calculateService = (items) =>
  formatPriceNumber(subtotal(items) * SERVICE_FEE);
export const calculateTax = (items) =>
  formatPriceNumber(subtotal(items) * SERVICE_FEE_MULTIPLIER * TAX_FEE);
export const calculateTotal = (items) =>
  formatPriceNumber(
    subtotal(items) * SERVICE_FEE_MULTIPLIER * TAX_FEE_MULTIPLIER
  );

export const checkRole = (user_role = '', role = '') => {
  const userRole = user_role?.toLowerCase();
  return userRole === role;
};

export const calculateTimeLeft = (endTime, duration) => {
  const end = moment(endTime).add(duration, 'seconds');
  const now = moment();
  const timeLeft = moment.duration(end.diff(now));
  return timeLeft.asSeconds();
};

export const calculateTimeElapsed = (startTime) => {
  const start = moment(startTime);
  const now = moment();
  const duration = moment.duration(now.diff(start));
  return Math.max(0, duration.asSeconds());
};

export const formatSecondsToTime = (seconds) => {
  return moment.utc(seconds * 1000).format('HH:mm:ss');
};
