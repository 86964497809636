import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import MinusCircledIcon from 'assets/icons/MinusCircledIcon';
import PlusCircledIcon from 'assets/icons/PlusCircledIcon';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ADD_BILL_DETAIL,
  GET_ALL_MENU,
  GET_BILL,
  GET_WAITING_LIST_DETAIL,
} from 'utils/constants/api';
import useFetch from 'utils/hooks/useFetch';
import food from 'assets/images/food.png';
import { useAuth } from 'utils/context/AuthContext';
import SpinnerIcon from 'assets/icons/SpinnerIcon';
import SuccessModal from 'components/modal/SuccessModal';
import KejoraButton from 'components/kejora/KejoraButton';
import { socket } from 'api';

function AddOrderCart() {
  const { user } = useAuth();

  const navigate = useNavigate();
  const params = useParams();

  const {
    data: bill,
    loading: loadingBillDetail,
    fetch: getBillDetail,
  } = useFetch(GET_BILL);
  const {
    data: menu,
    loading: loadingMenu,
    fetch: getAllMenu,
  } = useFetch(GET_ALL_MENU);
  const {
    data: waitingDetails,
    loading: loadingWaitingDetails,
    fetch: getWaitingDetails,
  } = useFetch(GET_WAITING_LIST_DETAIL);
  const {
    error: errorAddBillDetail,
    isSubmitting: isSubmittingAddBillDetail,
    fetch: addBillDetail,
  } = useFetch(ADD_BILL_DETAIL);

  const [data, setData] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const totalPrice =
    data?.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.price * currentItem.qty;
    }, 0) || 0;

  const addItem = (item) => {
    const _cart = localStorage.getItem('ca');
    const cart = JSON.parse(_cart);
    const updatedCart = cart?.map((c) =>
      c?.cd === item?.cd && c?.desc === item?.order_desc
        ? { ...c, qty: c.qty + 1 }
        : c
    );
    localStorage.setItem('ca', JSON.stringify(updatedCart));
    updateLocalCart();
  };

  const removeItem = (item) => {
    const _cart = localStorage.getItem('ca');
    const cart = JSON.parse(_cart);
    const updatedCart = cart?.map((c) =>
      c?.cd === item?.cd && c.qty > 1 && c?.desc === item?.order_desc
        ? { ...c, qty: c.qty - 1 }
        : c
    );
    localStorage.setItem('ca', JSON.stringify(updatedCart));
    updateLocalCart();
  };

  const deleteItem = (item) => {
    const _cart = localStorage.getItem('ca');
    const cart = JSON.parse(_cart);
    const updatedCart = cart?.filter(
      (c) => c.cd !== item.cd || c?.desc !== item?.order_desc
    );
    localStorage.setItem('ca', JSON.stringify(updatedCart));
    updateLocalCart();
  };

  const updateLocalCart = useCallback(() => {
    const _cart = localStorage.getItem('ca');
    const parsedCart = JSON.parse(_cart);
    const _data =
      parsedCart?.map((item, i) => {
        let _menu = [];
        menu?.data?.forEach((category) => {
          category?.menu?.forEach((item) => {
            _menu.push(item);
          });
        }, []);
        return {
          ...item,
          ..._menu.find((m) => m.cd === item.cd),
          order_desc: item?.desc,
        };
      }) || [];
    setData(_data);
  }, [menu]);

  const handleOrder = async () => {
    const _cart = localStorage.getItem('ca');
    const parsedCart = JSON.parse(_cart);
    const data = {
      bill_cd: bill?.bill_cd,
      orders: parsedCart.map((item) => ({
        ...item,
        user_nm: bill?.user_nm,
        created_by: user?.cd,
        qty: item.qty.toString(),
        menu_cd: item.cd,
      })),
    };
    const res = await addBillDetail({
      data,
    });
    if (!res?.error) {
      setShowSuccessModal(true);
      socket.emit('new-order', data);
      localStorage.removeItem('ca');

      setTimeout(() => {
        setShowSuccessModal(false);
        navigate(-1);
      }, 1500);
    }
  };

  useEffect(() => {
    updateLocalCart();
  }, [updateLocalCart]);

  useEffect(() => {
    window.addEventListener('storage', updateLocalCart);
    return () => {
      window.removeEventListener('storage', updateLocalCart);
    };
  }, [updateLocalCart]);

  useEffect(() => {
    getBillDetail({
      data: {
        table_cd: params.id,
      },
    }).then((res) => {
      getAllMenu({
        data: {
          bill_cd: res?.bill_cd,
        },
      });
      getWaitingDetails({
        data: {
          cd: res?.table_cd,
        },
      });
    });
  }, [getAllMenu, getBillDetail, getWaitingDetails, params.id]);

  const isLoading = loadingBillDetail || loadingMenu || loadingWaitingDetails;
  if (isLoading) {
    return (
      <div>
        <section className="app-main-container">
          <div className="grid grid-cols-3 -mx-4 px-4 py-1">
            <div className="h-5 w-5 animate-pulse bg-app-light-grey rounded-md" />
            <div className="flex flex-col items-center gap-2">
              <div className="h-5 w-20 animate-pulse bg-app-light-grey rounded-md" />
              <div className="h-5 w-36 animate-pulse bg-app-light-grey rounded-md" />
            </div>
          </div>
          <div className="grid grid-cols-1">
            {Array.from({ length: 3 })?.map((_, i) => (
              <div key={i} className="py-4 -mx-4 px-4 space-y-4">
                <div className="flex gap-2">
                  <div className="h-24 w-24 animate-pulse bg-app-light-grey rounded-md" />
                  <div className="flex-1 flex flex-col justify-between">
                    <div className="space-y-2 mb-2">
                      <div className="h-5 w-36 animate-pulse bg-app-light-grey rounded-md" />
                      <div className="h-5 w-48 animate-pulse bg-app-light-grey rounded-md" />
                      <div className="h-5 w-24 animate-pulse bg-app-light-grey rounded-md" />
                    </div>
                    <div className="ml-auto h-5 w-16 animate-pulse bg-app-light-grey rounded-md" />
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <div className="h-5 w-5 animate-pulse bg-app-light-grey rounded-full" />
                    <div className="h-5 w-12 animate-pulse bg-app-light-grey rounded-md" />
                    <div className="h-5 w-5 animate-pulse bg-app-light-grey rounded-full" />
                  </div>
                  <div>
                    <div className="h-5 w-24 animate-pulse bg-app-light-grey rounded-md" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <div className="fixed bottom-0 left-0 right-0 flex flex-col items-center justify-center z-30 p-4 bg-white shadow-xl gap-4">
          <div className="flex justify-between w-full text-sm">
            <div className="h-5 w-16 animate-pulse bg-app-light-grey rounded-md" />
            <div className="h-5 w-24 animate-pulse bg-app-light-grey rounded-md" />
          </div>
          <div className="h-8 w-full animate-pulse bg-app-light-grey rounded-md" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <section className="app-main-container">
        <div className="grid grid-cols-5 items-start -mx-4 px-4 py-1">
          <button onClick={() => navigate(-1)} className="text-left">
            <ArrowLeftIcon />
          </button>
          <div className="col-span-3">
            <p className="text-center font-semibold">Cart</p>
            <p className="text-center text-sm sm:text-base">
              {bill?.table_nm ||
                `Waiting List ${waitingDetails?.data?.number} - ${waitingDetails?.data?.nm}`}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1">
          {data?.map((item, i) => (
            <div key={i} className="py-4 -mx-4 px-4 space-y-4">
              <div className="flex gap-2">
                <img className="w-24 h-24" src={food} alt="food" />
                <div className="flex-1 flex flex-col justify-between">
                  <div className="space-y-2">
                    <p className="text-sm">{item.nm}</p>
                    {item.order_desc && (
                      <p className="text-xs">
                        Notes:{' '}
                        <span className="text-app-grey">{item.order_desc}</span>
                      </p>
                    )}
                    <p className="text-xs">
                      Rp {new Intl.NumberFormat().format(item.price || 0)}
                    </p>
                  </div>
                  <p className="text-right font-semibold text-xs">
                    Rp{' '}
                    {new Intl.NumberFormat().format(item.qty * item.price || 0)}
                  </p>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center gap-4">
                  <button onClick={() => removeItem(item)}>
                    <MinusCircledIcon />
                  </button>
                  <div className="text-sm">{item.qty}</div>
                  <button onClick={() => addItem(item)}>
                    <PlusCircledIcon />
                  </button>
                </div>
                <div>
                  <KejoraButton
                    onClick={() => deleteItem(item)}
                    text="Remove"
                    size="xs"
                    type="secondary"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <div className="fixed bottom-0 left-0 right-0 flex flex-col justify-center z-30 p-4 bg-white shadow-xl">
        <div className="flex justify-between w-full text-sm mb-4">
          <p className="font-semibold">Total</p>
          <p className="font-semibold">
            Rp {new Intl.NumberFormat().format(totalPrice)}
          </p>
        </div>
        <KejoraButton
          disabled={isSubmittingAddBillDetail || data?.length < 1}
          onClick={handleOrder}
          text={
            isSubmittingAddBillDetail ? (
              <SpinnerIcon className="h-6 w-6 fill-app-matte-black animate-spin mx-auto" />
            ) : (
              <>
                Order for{' '}
                <span className="font-semibold">
                  {bill?.table_nm ||
                    `Waiting List ${waitingDetails?.data?.number} - ${waitingDetails?.data?.nm}`}
                </span>
              </>
            )
          }
          fullWidth
          size="lg"
        />

        {errorAddBillDetail && (
          <div className="w-full">
            <p className="text-xs sm:text-sm text-red-500 text-right">
              Error adding order
            </p>
          </div>
        )}
      </div>
      {showSuccessModal && (
        <SuccessModal
          title="Order Placed Successfully"
          subtitle="The customer's order has been sent to the kitchen. Great job!"
        />
      )}
    </div>
  );
}

export default AddOrderCart;
