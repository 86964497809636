import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import KejoraButton from 'components/kejora/KejoraButton';
import PrintBillModal from 'components/modal/PrintBillModal';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { MODAL_INITIAL_STATE } from 'utils/constants';
import { GET_BILL_DETAIL_BY_CD } from 'utils/constants/api';
import useFetch from 'utils/hooks/useFetch';

function BillDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const { data: bill, fetch: getBillDetail } = useFetch(GET_BILL_DETAIL_BY_CD);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [printBillModal, setPrintBillModal] = useState(MODAL_INITIAL_STATE);
  useEffect(() => {
    getBillDetail({
      data: {
        bill_cd: params?.id,
      },
    });
  }, [getBillDetail, params?.id]);

  const handlePrintBill = async () => {
    handlePrint();
  };

  const Table = ({ data }) => {
    return (
      <table className="w-full table-auto">
        <tbody>
          {data?.map((order, i) => (
            <tr key={i}>
              <td className="text-left px-3 py-2">{order.qty}</td>
              <td className="text-left px-3 py-2 whitespace-nowrap">
                {order.menu_nm}
              </td>
              <td className="text-right px-3 py-2">
                Rp {new Intl.NumberFormat().format(order.total)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const totalPrice = bill?.data?.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.price * currentItem.qty;
  }, 0);

  return (
    <div className="app-main-container space-y-8">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <button onClick={() => navigate(-1)}>
            <ArrowLeftIcon />
          </button>
          <p className="font-semibold text-xl">Bill Details</p>
        </div>
      </div>
      <div className="flex gap-12">
        <div className="flex-1 flex gap-8">
          <div className="flex-1">
            <Table data={bill?.data?.filter((order, i) => i % 2 === 0)} />
          </div>
          <div className="flex-1">
            <Table data={bill?.data?.filter((order, i) => i % 2 !== 0)} />
          </div>
        </div>
        <div>
          <div className="flex flex-col gap-12 border border-app-light-grey rounded-xl p-5">
            <div className="grid grid-cols-2 gap-6">
              <div className="">Subtotal</div>
              <div className="whitespace-nowrap text-right">
                Rp {new Intl.NumberFormat().format(totalPrice || 0)}
              </div>
              <div className="">Servis</div>
              <div className="whitespace-nowrap text-right">
                Rp {new Intl.NumberFormat().format(totalPrice * 0.05 || 0)}
              </div>
              <div className="">PB1</div>
              <div className="whitespace-nowrap text-right">
                Rp{' '}
                {new Intl.NumberFormat().format(
                  (totalPrice + totalPrice * 0.05) * 0.1 || 0
                )}
              </div>
              <div className="font-bold">Total</div>
              <div className="font-bold whitespace-nowrap text-right">
                Rp{' '}
                {new Intl.NumberFormat().format(totalPrice * 1.05 * 1.1 || 0)}
              </div>
            </div>
            <KejoraButton
              text="Print Bill"
              onClick={() =>
                setPrintBillModal({ isOpen: true, data: bill?.data })
              }
              size="lg"
            />
          </div>
        </div>
      </div>
      {printBillModal.isOpen && (
        <PrintBillModal
          onClose={() => setPrintBillModal({ isOpen: false })}
          printRef={componentRef}
          handlePrint={handlePrintBill}
          bill_cd={params?.id}
        />
      )}
    </div>
  );
}

export default BillDetails;
