import React from 'react';

function LanguageIcon({
  className = 'stroke-app-matte-black w-6 h-6 stroke-2',
}) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <circle cx="12" cy="12" r="10" />
      <path
        stroke-linecap="round"
        d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z"
      />
      <path stroke-linecap="round" d="M2.5 9L21.5 9M2.5 15L21.5 15" />
    </svg>
  );
}

export default LanguageIcon;
