import React from 'react'

function ArrowRightIcon({
    className = "h-8 w-8 fill-app-matte-black"
}) {
    return (
        <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5628 16L10.9628 9.39998L12.8482 7.51465L21.3335 16L12.8482 24.4853L10.9628 22.5986L17.5628 15.9986V16Z" />
        </svg>
    )
}

export default ArrowRightIcon