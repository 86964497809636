import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import KejoraStatus from '../KejoraStatus';

function KejoraCard({
  key,
  title,
  isEmpty,
  detailsPath,
  content,
  status,
  showButton,
  buttonOnClick,
  buttonText,
}) {
  const navigate = useNavigate();

  return (
    <div
      key={key}
      className="flex flex-col justify-between border border-app-light-grey px-5 py-4 rounded-xl space-y-4"
    >
      <div className="flex justify-between items-center gap-4">
        <p className="font-semibold">{title}</p>
        {!isEmpty && (
          <button
            className="p-2 active:outline transition-all"
            onClick={() => navigate(detailsPath)}
          >
            <ArrowRightIcon />
          </button>
        )}
      </div>
      {content}
      <div className="flex justify-between items-end gap-4">
        <KejoraStatus text={status} />
        {showButton && (
          <button
            className="whitespace-nowrap font-semibold underline"
            onClick={buttonOnClick}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
}

export default KejoraCard;
