import AppModal from './components/AppModal';
import KejoraButton from 'components/kejora/KejoraButton';

import useFetch from 'utils/hooks/useFetch';
import { useAuth } from 'utils/context/AuthContext';
import { CLOSE_TABLE_SESSION } from 'utils/constants/api';
import ConfirmationModal from './ConfirmationModal';
import { useState } from 'react';

function CloseTableModal({ data, onClose }) {
  const { user } = useAuth();
  const { isSubmitting: submittingCloseTable, fetch: closeTable } =
    useFetch(CLOSE_TABLE_SESSION);

  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    data: null,
  });
  const handleCloseTable = async () => {
    setConfirmationModal({
      isOpen: true,
      data: {
        title: 'Confirmation',
        body: 'Are you sure you want to close this table?',
        secondaryText: 'Cancel',
        primaryText: 'Confirm',
        onSuccess: async () => {
          await closeTable({
            data: {
              table_cd: data?.table_cd,
              closed_by: user?.cd,
            },
          });
          window.location.reload();
        },
      },
    });
  };

  return (
    <AppModal onClose={onClose} withClose>
      <div className="space-y-8">
        <h1 className="text-2xl font-semibold text-center">Close Table</h1>
        <div className="grid grid-cols-3">
          <div className="flex flex-col items-center">
            <p>Table</p>
            <p className="text-lg sm:text-xl font-semibold">{data?.table_cd}</p>
          </div>
          <div className="flex flex-col items-center">
            <p>Duration</p>
            <p className="text-lg sm:text-xl font-semibold">{data?.duration}</p>
          </div>
          <div className="flex flex-col items-center">
            <p>Name</p>
            <p className="text-lg sm:text-xl font-semibold">{data?.user_nm}</p>
          </div>
        </div>
        <div>
          <KejoraButton
            disabled={submittingCloseTable}
            onClick={handleCloseTable}
            text="Close Table"
            type="primary"
            fullWidth
            size="lg"
          />
        </div>
      </div>
      {confirmationModal.isOpen && (
        <ConfirmationModal
          data={confirmationModal.data}
          isSubmitting={submittingCloseTable}
          onClose={() => setConfirmationModal({ isOpen: false, data: null })}
        />
      )}
    </AppModal>
  );
}

export default CloseTableModal;
