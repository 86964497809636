import React from 'react'

function PlusCircledIcon({
   className = "w-6 h-6 stroke-app-matte-black"
}) {
   return (
      <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <circle cx="12" cy="12" r="10" strokeWidth="2" />
         <path d="M12 15L12 9" strokeWidth="2" strokeLinecap="square" />
         <path d="M15 12L9 12" strokeWidth="2" strokeLinecap="square" />
      </svg>
   )
}

export default PlusCircledIcon