import React, { useEffect } from 'react';
import { GET_ALL_ORDER_KITCHEN, UPDATE_BILL_DETAIL } from 'utils/constants/api';
import useFetch from 'utils/hooks/useFetch';
import moment from 'moment';

function OrderList() {
  const {
    data: order,
    loading: loadingOrder,
    fetch: getOrderKitchen,
  } = useFetch(GET_ALL_ORDER_KITCHEN);
  const { fetch: updateBill } = useFetch(UPDATE_BILL_DETAIL);

  useEffect(() => {
    getOrderKitchen({
      data: {},
    });
  }, [getOrderKitchen]);

  if (loadingOrder) {
    return (
      <section className="app-kitchen-container font-semibold text-2xl">
        Loading...
      </section>
    );
  }

  return (
    <div>
      <section className="app-kitchen-container">
        <p className="font-semibold text-3xl">Order List</p>

        <div className="grid grid-cols-3 gap-4 py-4">
          {order?.data?.map((item, i) => (
            <div key={i} className="border border-app-light-grey px-5 py-4">
              <div className="flex justify-between  py-4">
                <span className="font-bold text-xl">{item.table_nm}</span>
                <span className="text-right text-xl">
                  {moment(item.table_dt).format('HH:mm')}
                </span>
              </div>
              <table className="table-fixed text-sm w-full border-b border-app-matte-black">
                <thead>
                  <tr>
                    <th className="text-left px-1 py-2 border-b border-app-matte-black">
                      Name
                    </th>
                    <th className="text-center px-1 py-2 border-b border-app-matte-black w-12">
                      Qty
                    </th>
                    <th className="text-center px-1 py-2 border-b border-app-matte-black w-28">
                      Order Time
                    </th>
                    <th className="text-center px-1 py-2 border-b border-app-matte-black w-12">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {item.orders.map((orderDetail, i) => (
                    <tr key={i}>
                      <td className="text-left px-1 py-2" for="checkBoxes">
                        {orderDetail.menu_nm}
                      </td>
                      <td
                        className="text-center px-1 py-2 w-12"
                        for="checkBoxes"
                      >
                        {orderDetail.bill_dtl_qty}
                      </td>
                      <td
                        className="text-center px-1 py-2 w-28"
                        for="checkBoxes"
                      >
                        {moment(orderDetail.bill_dtl_dt).format('HH:mm')}
                      </td>
                      <td className="text-center px-1 py-2 w-12">
                        <input
                          id="checkBoxes"
                          type="checkbox"
                          onClick={() => {
                            updateBill({
                              data: {
                                cd: orderDetail.bill_dtl_cd,
                                process_status: 'DONE',
                              },
                            }).then((response) => {
                              window.location.reload(false);
                            });
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
export default OrderList;
