import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'react-qr-code';
import { v4 as uuidv4 } from 'uuid';

import {
  CUSTOMER_SITE_URL,
  ADD_BILL,
  ADD_WAITING_LIST,
} from 'utils/constants/api';
import useFetch from 'utils/hooks/useFetch';
import AppModal from './components/AppModal';
import { useAuth } from 'utils/context/AuthContext';
import KejoraButton from 'components/kejora/KejoraButton';

function AddListModal({ onClose, data }) {
  const { user } = useAuth();

  const { isSubmitting: isSubmittingAddBill, fetch: addBill } =
    useFetch(ADD_BILL);
  const { isSubmitting: isSUbmittingAddWaitingList, fetch: addWaitingList } =
    useFetch(ADD_WAITING_LIST);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleClose = () => {
    onClose();
    window.location.reload();
  };
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [qrCode, setQrCode] = useState('');
  const [addNameError, setAddNameError] = useState(false);

  const QRToPrint = React.forwardRef((props, ref) => {
    return (
      <div
        ref={ref}
        className="flex flex-col gap-8 items-center w-min mx-auto justify-center pr-14"
      >
        <div className="space-y-4">
          <p className="font-bold text-lg text-center">
            Table {props?.table_cd}
          </p>
          <QRCode size={128} value={`${CUSTOMER_SITE_URL}?code=${qrCode}`} />
        </div>
        <p className="text-xs text-center">
          {`${CUSTOMER_SITE_URL}?code=${qrCode}`}
        </p>
      </div>
    );
  });

  const handleConfirm = async () => {
    if (name) {
      const res = await addWaitingList({
        data: {
          nm: name,
        },
      });

      if (!res?.error) {
        await addBill({
          data: {
            cd: qrCode,
            table_cd: data?.table_cd || res?.data?.cd,
            created_by: user?.cd,
            user_nm: name,
          },
        });
        setPage(2);
      } else {
        setAddNameError(true);
      }
    }
  };

  useEffect(() => {
    setQrCode(uuidv4());
  }, []);

  return (
    <AppModal onClose={handleClose} withClose>
      {page === 2 && name ? (
        <div className="space-y-4">
          <div className="-mr-14">
            <QRToPrint ref={componentRef} table_cd={data?.table_cd} />
          </div>
          <KejoraButton
            onClick={handlePrint}
            size="lg"
            fullWidth
            text="Print QR Code"
          />
        </div>
      ) : (
        <div className="space-y-4">
          <input
            className={`rounded-md border ${
              addNameError ? 'border-red-500' : 'border-app-matte-black'
            }  p-4 w-full`}
            placeholder="Input name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {addNameError && (
            <p className="text-sm text-red-500">
              Mohon jangan gunakan nama yang sama untuk waiting list
            </p>
          )}
          <KejoraButton
            disabled={isSubmittingAddBill || isSUbmittingAddWaitingList}
            onClick={handleConfirm}
            size="lg"
            fullWidth
            text="Confirm"
          />
        </div>
      )}
    </AppModal>
  );
}

export default AddListModal;
