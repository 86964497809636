import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import PlusIcon from 'assets/icons/PlusIcon';

import AddListModal from 'components/modal/AddListModal';
import QRModal from 'components/modal/QRModal';
import AddMenuModal from 'components/modal/AddMenuModal';
import PoolTableCard from 'components/table-page/PoolTableCard';
import StartTableModal from 'components/modal/StartTableModal';
import KejoraButton from 'components/kejora/KejoraButton';
import KejoraCard from 'components/kejora/KejoraCard';
import CloseTableModal from 'components/modal/CloseTableModal';

import { useLanguage } from 'utils/context/LanguageContext';
import { GET_ALL_WAITING_LIST, GET_TABLE } from 'utils/constants/api';
import useFetch from 'utils/hooks/useFetch';
import { HOME_PAGE } from 'utils/constants/data';
import { MODAL_INITIAL_STATE } from 'utils/constants';

function Home() {
  const { language } = useLanguage();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: table,
    loading: loadingGetTable,
    error: errorGetTable,
    fetch: getTable,
  } = useFetch(GET_TABLE);
  const { data: waitingList, fetch: getAllWaitingList } =
    useFetch(GET_ALL_WAITING_LIST);

  const [qrModal, setQrModal] = useState(MODAL_INITIAL_STATE);
  const [addListModal, setAddListModal] = useState(MODAL_INITIAL_STATE);
  const [addMenuModal, setAddMenuModal] = useState(MODAL_INITIAL_STATE);
  const [startTableModal, setStartTableModal] = useState(MODAL_INITIAL_STATE);
  const [closeTableModal, setCloseTableModal] = useState(MODAL_INITIAL_STATE);
  const [_table, setTable] = useState([]);
  const [activeTab, setActiveTab] = useState(null);

  const handleChangeTab = useCallback(
    (tab) => {
      setActiveTab(tab);
      setSearchParams({ tab: tab.id });
    },
    [setSearchParams]
  );

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(HOME_PAGE[language].TABS.find((t) => t.id === Number(tab)));
    } else {
      setActiveTab(HOME_PAGE[language].TABS[0]);
      setSearchParams({ tab: HOME_PAGE[language].TABS[0].id });
    }
  }, [language, searchParams, setSearchParams]);

  useEffect(() => {
    getTable({
      data: {},
    });
  }, [getTable]);

  useEffect(() => {
    getAllWaitingList({
      data: {},
    });
  }, [getAllWaitingList]);

  useEffect(() => {
    const _table = table?.data?.map((table_data) => {
      let countdown = 0;
      setInterval(() => {
        countdown = countdown + 1;
      }, 1000);

      return {
        ...table_data,
        countdown,
      };
    });
    setTable(_table);
  }, [table]);

  return (
    <div>
      <section className="app-main-container space-y-8">
        <div className="flex space-x-2 overflow-x-auto -mx-4 px-4 py-2 sm:mx-0 sm:px-0">
          {HOME_PAGE[language].TABS.map((tab) => (
            <KejoraButton
              className="whitespace-nowrap"
              key={tab.id}
              onClick={() => handleChangeTab(tab)}
              text={tab.title}
              type={activeTab?.id === tab?.id ? 'primary' : 'secondary'}
            />
          ))}
        </div>

        <div className="flex flex-col sm:flex-row gap-4 justify-between">
          <p className="font-semibold text-3xl">
            {
              HOME_PAGE[language].TABS.find((tab) => tab?.id === activeTab?.id)
                ?.title
            }
          </p>
          <div className="flex gap-2">
            {activeTab?.id === HOME_PAGE[language].TABS?.[2]?.id && (
              <KejoraButton
                onClick={() =>
                  setAddListModal({ isOpen: true, data: { table_cd: null } })
                }
                icon={<PlusIcon />}
                text={HOME_PAGE[language].ADD_LIST}
              />
            )}
          </div>
        </div>

        {activeTab?.id === HOME_PAGE[language].TABS?.[0]?.id && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {loadingGetTable || errorGetTable
              ? Array.from({ length: 15 }).map((_, i) => (
                  <div
                    key={i}
                    className="flex flex-col justify-between border border-app-light-grey px-5 py-4 rounded-lg space-y-4"
                  >
                    <div className="flex justify-between items-center gap-4">
                      <div className="h-6 w-48 animate-pulse bg-app-light-grey rounded-md" />
                      <div className="h-6 w-6 animate-pulse bg-app-light-grey rounded-md" />
                    </div>
                    <div className="flex justify-between gap-4">
                      <div className="text-white font-medium bg-app-light-grey rounded-lg h-6 w-24 animate-pulse" />
                      <div className="text-white font-medium bg-app-light-grey rounded-lg h-6 w-16 animate-pulse" />
                    </div>
                  </div>
                ))
              : _table?.map((table_data, i) => (
                  <PoolTableCard
                    i={i}
                    table_data={table_data}
                    setStartTableModal={setStartTableModal}
                    setCloseTableModal={setCloseTableModal}
                  />
                ))}
          </div>
        )}

        {activeTab?.id === HOME_PAGE[language].TABS?.[1]?.id && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {loadingGetTable || errorGetTable
              ? Array.from({ length: 15 }).map((_, i) => (
                  <div
                    key={i}
                    className="flex flex-col justify-between border border-app-light-grey px-5 py-4 rounded-lg space-y-4"
                  >
                    <div className="flex justify-between items-center gap-4">
                      <div className="h-6 w-48 animate-pulse bg-app-light-grey rounded-md" />
                      <div className="h-6 w-6 animate-pulse bg-app-light-grey rounded-md" />
                    </div>
                    <div className="flex justify-between gap-4">
                      <div className="text-white font-medium bg-app-light-grey rounded-lg h-6 w-24 animate-pulse" />
                      <div className="text-white font-medium bg-app-light-grey rounded-lg h-6 w-16 animate-pulse" />
                    </div>
                  </div>
                ))
              : _table?.map((table_data, i) => (
                  <KejoraCard
                    key={i}
                    title={`${table_data?.nm}${
                      table_data?.user_nm ? ` - ${table_data?.user_nm}` : ''
                    }`}
                    isEmpty={table_data?.status === 'EMPTY'}
                    detailsPath={`/table/${table_data.cd}`}
                    status={table_data.status}
                    showButton={table_data.status === 'EMPTY'}
                    buttonOnClick={() =>
                      setQrModal({
                        isOpen: true,
                        data: { table_cd: table_data?.cd },
                      })
                    }
                    buttonText="QR Code"
                  />
                ))}
          </div>
        )}

        {activeTab?.id === HOME_PAGE[language].TABS?.[2]?.id && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {loadingGetTable || errorGetTable
              ? Array.from({ length: 15 }).map((_, i) => (
                  <div
                    key={i}
                    className="flex flex-col justify-between border border-app-light-grey px-5 py-4 rounded-lg space-y-4"
                  >
                    <div className="flex justify-between items-center gap-4">
                      <div className="h-6 w-48 animate-pulse bg-app-light-grey rounded-md" />
                      <div className="h-6 w-6 animate-pulse bg-app-light-grey rounded-md" />
                    </div>
                    <div className="flex justify-between gap-4">
                      <div className="text-white font-medium bg-app-light-grey rounded-lg h-6 w-24 animate-pulse" />
                      <div className="text-white font-medium bg-app-light-grey rounded-lg h-6 w-16 animate-pulse" />
                    </div>
                  </div>
                ))
              : waitingList?.data?.map((table_data, i) => (
                  <KejoraCard
                    key={i}
                    title={`Waiting List ${table_data.number}`}
                    isEmpty={table_data?.status === 'EMPTY'}
                    detailsPath={`/waiting/${table_data.cd}`}
                    content={<p>Name: {table_data.nm}</p>}
                    status={table_data.status}
                    showButton={table_data.status === 'EMPTY'}
                    buttonOnClick={() =>
                      setQrModal({
                        isOpen: true,
                        data: { table_cd: table_data?.cd },
                      })
                    }
                    buttonText="QR Code"
                  />
                ))}
          </div>
        )}
      </section>

      {qrModal.isOpen && (
        <QRModal
          data={qrModal?.data}
          onClose={() => setQrModal({ isOpen: false })}
        />
      )}
      {addListModal.isOpen && (
        <AddListModal
          data={addListModal?.data}
          onClose={() => setAddListModal({ isOpen: false })}
        />
      )}

      {addMenuModal.isOpen && (
        <AddMenuModal onClose={() => setAddMenuModal({ isOpen: false })} />
      )}

      {startTableModal.isOpen && (
        <StartTableModal
          data={startTableModal?.data}
          onClose={() => setStartTableModal({ isOpen: false })}
        />
      )}
      {closeTableModal.isOpen && (
        <CloseTableModal
          data={closeTableModal?.data}
          onClose={() => setCloseTableModal({ isOpen: false })}
        />
      )}
    </div>
  );
}

export default Home;
