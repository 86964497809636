import React, { useEffect, useState } from 'react';
import MinusCircledIcon from 'assets/icons/MinusCircledIcon';
import PlusCircledIcon from 'assets/icons/PlusCircledIcon';
import foodHD from 'assets/images/food_hd.png';
import AppModal from './components/AppModal';
import KejoraButton from 'components/kejora/KejoraButton';

function EditModal({ onClose, name, image_url, cd, onEditOrder, initialQty }) {
  const [quantity, setQuantity] = useState(1);

  const addItem = () => {
    setQuantity((prev) => prev + 1);
  };

  const removeItem = () => {
    setQuantity((prev) => prev - 1);
  };

  useEffect(() => {
    setQuantity(initialQty);
  }, [initialQty]);

  return (
    <AppModal withClose onClose={onClose}>
      <div>
        <img className="aspect-square w-full" src={foodHD} alt="food" />
      </div>
      <div>
        <p className="text-sm">{name}</p>
      </div>
      <div className="flex justify-evenly items-center gap-4">
        <button onClick={removeItem}>
          <MinusCircledIcon className="w-10 h-10 stroke-app-matte-black" />
        </button>
        <div>{quantity}</div>
        <button onClick={addItem}>
          <PlusCircledIcon className="w-10 h-10 stroke-app-matte-black" />
        </button>
      </div>
      <KejoraButton
        onClick={() => onEditOrder(cd, quantity)}
        text="Edit Order"
        fullWidth
        size="lg"
      />
    </AppModal>
  );
}

export default EditModal;
