import React from 'react';
import AddMenu from 'pages/AddMenu';

function AddMenuModal({ onClose }) {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center z-40 rounded-lg">
      <div className="bg-white z-50 w-8/12 flex flex-col shadow-md rounded-lg">
        <AddMenu onClose={onClose} />
      </div>
      <div className="fixed top-0 bottom-0 left-0 right-0 bg-app-matte-black opacity-60 z-40" />
    </div>
  );
}

export default AddMenuModal;
