export const ORDER_LIST = {
  en: 'Order List',
  id: 'Daftar Pesanan',
};

export const ADD_ORDER = {
  en: 'Add Order',
  id: 'Tambah Pesanan',
};

export const QR_CODE = {
  en: 'QR Code',
  id: 'Kode QR',
};

export const SUBTOTAL = {
  en: 'Subtotal',
  id: 'Subtotal',
};

export const TAX = {
  en: 'Tax',
  id: 'Pajak',
};

export const SERVICE = {
  en: 'Service Charge',
  id: 'Biaya Layanan',
};

export const TOTAL = {
  en: 'Total',
  id: 'Total',
};

export const CLOSE_BILL = {
  en: 'Close Bill',
  id: 'Tutup Tagihan',
};

export const SPLIT_BILL = {
  en: 'Split Bill',
  id: 'Pisahkan Tagihan',
};

export const PRINT_BILL = {
  en: 'Print Bill',
  id: 'Cetak Tagihan',
};

export const EMPTY_ORDER_LIST = {
  en: 'Empty Order List',
  id: 'Daftar Pesanan Kosong',
};

export const HOME_PAGE = {
  en: {
    BILL_HISTORY: 'Bill History',
    ADD_LIST: 'Add List',
    START: 'Start',
    CLOSE: 'Close',
    TIME_LEFT: 'Time Left',
    TIME_ELAPSED: 'Time Elapsed',
    TABS: [
      {
        id: 1,
        title: 'Billiard Table',
      },
      {
        id: 2,
        title: 'Dining Table',
      },
      {
        id: 3,
        title: 'Waiting List',
      },
    ],
  },
  id: {
    BILL_HISTORY: 'Riwayat Tagihan',
    ADD_LIST: 'Tambah Daftar',
    START: 'Mulai',
    CLOSE: 'Tutup',
    TIME_LEFT: 'Sisa Waktu',
    TIME_ELAPSED: 'Waktu Berlalu',
    TABS: [
      {
        id: 1,
        title: 'Meja Billiard',
      },
      {
        id: 2,
        title: 'Meja Makan',
      },
      {
        id: 3,
        title: 'Daftar Tunggu',
      },
    ],
  },
};

export const TABLE_DETAILS_PAGE = {
  en: {},
  id: {},
};

export const ROLE = {
  en: {
    ADMIN: {
      id: 1,
      title: 'Admin',
    },
    OWNER: {
      id: 2,
      title: 'Owner',
    },
    KITCHEN: {
      id: 3,
      title: 'Kitchen',
    },
    BARISTA: {
      id: 4,
      title: 'Barista',
    },
    CASHIER: {
      id: 5,
      title: 'Cashier',
    },
    WAITER: {
      id: 6,
      title: 'Waiter',
    },
  },
  id: {
    ADMIN: {
      id: 1,
      title: 'Admin',
    },
    OWNER: {
      id: 2,
      title: 'Pemilik',
    },
    KITCHEN: {
      id: 3,
      title: 'Dapur',
    },
    BARISTA: {
      id: 4,
      title: 'Barista',
    },
    CASHIER: {
      id: 5,
      title: 'Kasir',
    },
    WAITER: {
      id: 6,
      title: 'Pelayan',
    },
  },
};

export const STATUS = {
  en: {
    FIXED: {
      id: 1,
      title: 'Fixed',
    },
    OPEN: {
      id: 2,
      title: 'Open',
    },
    OCCUPIED: {
      id: 3,
      title: 'Occupied',
    },
    CLOSED: {
      id: 4,
      title: 'Closed',
    },
    EMPTY: {
      id: 5,
      title: 'Empty',
    },
  },
  id: {
    FIXED: {
      id: 1,
      title: 'Tetap',
    },
    OPEN: {
      id: 2,
      title: 'Buka',
    },
    OCCUPIED: {
      id: 3,
      title: 'Terisi',
    },
    CLOSED: {
      id: 4,
      title: 'Tutup',
    },
    EMPTY: {
      id: 5,
      title: 'Kosong',
    },
  },
};
