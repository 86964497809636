import React from 'react';
import KejoraCard from 'components/kejora/KejoraCard';
import { HOME_PAGE } from 'utils/constants/data';
import { useLanguage } from 'utils/context/LanguageContext';
import { formatSecondsToTime } from 'utils/helpers';
import useTableTimer from 'utils/hooks/useTableTimer';

function PoolTableCard({
  i,
  table_data,
  setStartTableModal,
  setCloseTableModal,
}) {
  const { language } = useLanguage();

  const time = useTableTimer(
    table_data?.session_status,
    table_data?.session_created_dt,
    table_data?.session_amount
  );

  return (
    <KejoraCard
      key={i}
      title={`${table_data?.nm}${
        table_data?.user_nm ? ` - ${table_data?.user_nm}` : ''
      }`}
      isEmpty={table_data?.status === 'EMPTY'}
      detailsPath={`/table/${table_data.cd}`}
      content={
        time ? (
          <div className="flex space-x-2 items-center">
            <div>
              {table_data?.session_status === 'FIXED' &&
                HOME_PAGE[language].TIME_LEFT}
              {table_data?.session_status === 'OPEN' &&
                HOME_PAGE[language].TIME_ELAPSED}
              :{' '}
            </div>
            <div className="whitespace-nowrap">{time}</div>
          </div>
        ) : (
          <div className="h-10" />
        )
      }
      showButton
      status={table_data.session_status}
      buttonOnClick={() =>
        table_data?.session_status === 'FIXED' ||
        table_data?.session_status === 'OPEN'
          ? setCloseTableModal({
              isOpen: true,
              data: {
                table_cd: table_data?.cd,
                user_nm: table_data?.user_nm,
                duration:
                  table_data?.session_status === 'FIXED'
                    ? formatSecondsToTime(table_data?.session_amount)
                    : time,
              },
            })
          : setStartTableModal({
              isOpen: true,
              data: {
                table_cd: table_data?.cd,
              },
            })
      }
      buttonText={
        table_data?.session_status === 'FIXED' ||
        table_data?.session_status === 'OPEN'
          ? HOME_PAGE[language].CLOSE
          : HOME_PAGE[language].START
      }
    />
  );
}

export default PoolTableCard;
