import React, { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import useFetch from 'utils/hooks/useFetch';
import 'react-dropdown/style.css';
import { ADD_MENU, GET_ALL_CATEGORY } from 'utils/constants/api';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import CloseSquaredIcon from 'assets/icons/CloseSquaredIcon';
import { MODAL_INITIAL_STATE } from 'utils/constants';

function AddMenu({ onClose }) {
  const { fetch: addMenuItem } = useFetch(ADD_MENU);
  const { fetch: getCategory } = useFetch(GET_ALL_CATEGORY);
  const [options, setOptions] = useState([]);

  const [itemName, setItemName] = useState();
  const [itemPrice, setItemPrice] = useState();
  const [itemCategory, setItemCategory] = useState();
  const [itemDescription, setItemDescription] = useState();
  const [confirmationModal, setConfirmationModal] =
    useState(MODAL_INITIAL_STATE);

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  function numberOnly(event) {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }
  function addMenu(itemName, itemPrice, itemCategory, itemDescription) {
    setConfirmationModal({
      isOpen: true,
      data: {
        onSuccess: async () => {
          await addMenuItem({
            data: {
              nm: itemName,
              img: (itemName + '.png').replaceAll(' ', '_'),
              price: parseInt(itemPrice),
              desc: itemDescription,
              category_cd: itemCategory.value,
              created_by: 'admin',
            },
          });
          window.location.reload();
        },
      },
    });
  }
  useEffect(() => {
    getCategory({
      data: {},
    }).then((response) => {
      response?.data?.map((x) =>
        setOptions((current) => [...current, ...x.nm])
      );
    });
  }, [getCategory]);

  return (
    <div className="w-full mx-auto rounded-lg">
      <div className="app-add-menu-header-container bg-black text-white flex justify-between">
        <h1 className="px-4 font-lg">Add Menu</h1>
        <button onClick={onClose}>
          <CloseSquaredIcon className="h-6 w-6 fill-white" />
        </button>
      </div>
      <div className="flex app-main-container bg-slate-300 px-4">
        <div className="w-8/12 px-6 flex flex-col ">
          <div className="border border-app-light-grey bg-white h-64 rounded-md">
            <img className="object-scale-down h-full" alt="file" src={file} />
          </div>
          <input
            className="hidden"
            id="selectedFile"
            type="file"
            onChange={handleChange}
          />
          <button
            onClick={() => {
              document.getElementById('selectedFile').click();
            }}
            className="mt-5 px-4 py-2 bg-black text-white rounded-md"
          >
            Add Image
          </button>
        </div>
        <div className="flex flex-col px-6 w-full">
          <input
            value={itemName}
            onChange={(event) => {
              setItemName(event.target.value);
            }}
            className="px-4 py-2 rounded-md w-full"
            placeholder="Item Name"
          ></input>
          <input
            value={itemPrice}
            onKeyPress={(event) => {
              numberOnly(event);
            }}
            onChange={(event) => {
              setItemPrice(event.target.value);
            }}
            className="mt-5 px-4 py-2 rounded-md w-full"
            placeholder="Price"
          ></input>
          <input
            value={itemDescription}
            onChange={(event) => {
              setItemDescription(event.target.value);
            }}
            className="mt-5 px-4 py-2 rounded-md w-full"
            placeholder="Item Description"
          ></input>
          <Dropdown
            className="mt-5"
            options={options}
            value={itemCategory}
            onChange={(value) => {
              setItemCategory(value);
            }}
            placeholder="Select category"
          />
          <button
            onClick={() => {
              addMenu(itemName, itemPrice, itemCategory, itemDescription);
            }}
            className="ml-auto mt-5 px-3 py-2 bg-black text-white w-24 rounded-md"
          >
            Save
          </button>
        </div>
      </div>
      {confirmationModal.isOpen && (
        <ConfirmationModal
          onClose={() => setConfirmationModal({ isOpen: false, data: {} })}
          data={confirmationModal?.data}
        />
      )}
    </div>
  );
}

export default AddMenu;
