import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import MainContainer from 'components/layout/MainContainer';

import { routes } from 'utils/constants';
import { AuthProvider } from 'utils/context/AuthContext';
import { LanguageProvider } from 'utils/context/LanguageContext';

let router = createBrowserRouter(routes);

function App() {
  return (
    <LanguageProvider>
      <AuthProvider>
        <MainContainer>
          <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
        </MainContainer>
      </AuthProvider>
    </LanguageProvider>
  );
}

export default App;
