import AvatarIcon from 'assets/icons/AvatarIcon';
import CloseSquaredIcon from 'assets/icons/CloseSquaredIcon';
import HomeIcon from 'assets/icons/HomeIcon';
import LanguageIcon from 'assets/icons/LanguageIcon';
import ListIcon from 'assets/icons/ListIcon';
import KejoraButton from 'components/kejora/KejoraButton';
import Cookies from 'js-cookie';
import React from 'react';
import { HOME_PAGE } from 'utils/constants/data';
import { useAuth } from 'utils/context/AuthContext';
import { useLanguage } from 'utils/context/LanguageContext';

function Sidebar({ isOpen, onClose }) {
  const { user } = useAuth();
  const { language, changeLanguage } = useLanguage();
  const menu = [
    {
      name: 'Home',
      link: '/',
      icon: HomeIcon,
    },
    {
      name: HOME_PAGE[language].BILL_HISTORY,
      link: '/bill-history',
      icon: ListIcon,
    },
  ];

  const handleLanguageChange = () => {
    language === 'id' ? changeLanguage('en') : changeLanguage('id');
  };

  const handleLogout = () => {
    Cookies.remove('ut');
    window.location.reload();
  };

  return (
    <div
      className={`sm:hidden fixed right-0 top-0 bottom-0 bg-white/50 backdrop-blur-lg ${
        isOpen ? 'w-full opacity-100 ' : 'w-0 opacity-0'
      } z-50 transition-all overflow-hidden`}
    >
      <div className="app-nav-container h-full flex flex-col justify-between space-y-8">
        <div className="flex flex-col space-y-8">
          <div className="text-right font-medium mt-1">
            <button
              className="text-sm active:border-b-2 active:border-black transition-all"
              onClick={onClose}
            >
              <CloseSquaredIcon />
            </button>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex space-x-4 items-center">
              <div className="border-2 border-app-matte-black rounded-full p-2">
                <AvatarIcon />
              </div>
              <p className="font-semibold">{user?.name}</p>
            </div>
            <div className="flex items-center justify-center">
              <button onClick={handleLanguageChange}>
                <LanguageIcon
                  className={`${
                    language === 'id'
                      ? 'stroke-white/50 fill-app-matte-black w-6 h-6 stroke-2'
                      : 'stroke-app-matte-black w-6 h-6 stroke-2'
                  }`}
                />
              </button>
            </div>
          </div>

          <ul className="flex flex-col space-y-4 font-medium">
            {menu.map((item, index) => {
              const Icon = item.icon;
              return (
                <li className="flex space-x-2 items-center">
                  <Icon className="w-6 h-6 fill-app-matte-black" />
                  <a href={item.link}>{item.name}</a>
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <KejoraButton text="Logout" fullWidth onClick={handleLogout} />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
