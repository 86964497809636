import React, { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';

import DeleteIcon from 'assets/icons/DeleteIcon';
import useFetch from 'utils/hooks/useFetch';
import { CHECK_USER_CREDENTIAL, GET_USERS_BY_ROLE } from 'utils/constants/api';
import SpinnerIcon from 'assets/icons/SpinnerIcon';
import AvatarIcon from 'assets/icons/AvatarIcon';
import { ROLE_KASIR, ROLE_WAITER } from 'utils/constants';
import { useAuth } from 'utils/context/AuthContext';
import { checkRole } from 'utils/helpers';

const pin_buttons = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '7',
    label: '7',
  },
  {
    value: '8',
    label: '8',
  },
  {
    value: '9',
    label: '9',
  },
  {},
  {
    value: '0',
    label: '0',
  },
  {
    value: 'delete',
    label: <DeleteIcon />,
    is_delete_button: true,
  },
];

function Login() {
  const { user } = useAuth();
  const userToken = Cookies.get('ut');
  const allowed =
    user &&
    (checkRole(user?.role_cd, ROLE_KASIR) ||
      checkRole(user?.role_cd, ROLE_WAITER));

  const {
    error: errorCheckUserCredential,
    isSubmitting: submittingLogin,
    fetch: checkUserCredential,
  } = useFetch(CHECK_USER_CREDENTIAL);
  const {
    data: users,
    loading: loadingUsers,
    fetch: getUsersByRoles,
  } = useFetch(GET_USERS_BY_ROLE);

  const [changeAccountMode, setChangeAccountMode] = useState(false);
  const [_selectedUser, setSelectedUser] = useState('');
  const [pin, setPin] = useState('');
  const [errorPin, setErrorPin] = useState(false);
  const [_mode, setMode] = useState('');

  const mode = localStorage.getItem('md') || _mode;
  const selectedUser = JSON.parse(localStorage.getItem('un')) || _selectedUser;

  const handleSetMode = (value) => {
    setMode(value);
    localStorage.setItem('md', value);
  };

  function deleteLastLetter(inputString) {
    if (inputString.length > 0) {
      return inputString.slice(0, -1);
    } else {
      return inputString;
    }
  }

  const handleChange = (item) => {
    if (pin.length < 4) {
      setErrorPin('');
      if (item?.is_delete_button) {
        setPin((prev) => {
          return deleteLastLetter(prev);
        });
      } else {
        setPin((prev) => prev + item?.value);
      }
    }
  };

  const handleSuccess = useCallback(async () => {
    if (pin?.length === 4) {
      const res = await checkUserCredential({
        data: {
          username:
            selectedUser?.username ||
            JSON.parse(localStorage.getItem('un'))?.username,
          password: pin,
        },
      });
      Cookies.set('ut', res?.access_token);
      if (res.isAxiosError) {
        setPin('');
        setErrorPin(res?.response?.data?.data);
      } else {
        setPin('');
        setTimeout(() => {
          window.location.assign('/');
        }, 500);
      }
    }
  }, [checkUserCredential, pin, selectedUser?.username]);

  const handleChangeAccount = () => {
    setSelectedUser({});
    localStorage.removeItem('un');
    setChangeAccountMode(true);
  };

  const handleSelectUser = (user) => {
    const _user = { name: user?.name, username: user?.username };
    setSelectedUser(_user);
    localStorage.setItem('un', JSON.stringify(_user));
    setChangeAccountMode(false);
  };

  useEffect(() => {
    handleSuccess();
  }, [handleSuccess]);

  useEffect(() => {
    if (mode) {
      getUsersByRoles({
        data: {
          role: mode,
        },
      });
    }
  }, [getUsersByRoles, mode]);

  useEffect(() => {
    if (errorCheckUserCredential) {
      setErrorPin('Wrong PIN');
    }
  }, [errorCheckUserCredential]);

  useEffect(() => {
    if (userToken && allowed) {
      window.location.assign('/');
    }
  }, [allowed, userToken]);

  return (
    <div>
      {!mode ? (
        <section className="app-main-container flex flex-col justify-center gap-10">
          <div className="flex space-x-5">
            <p className="font-medium text-lg sm:text-2xl">Select user group</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 w-full">
            <button
              className="bg-app-matte-black text-white p-4 sm:p-8"
              onClick={() => handleSetMode(ROLE_KASIR)}
            >
              Kasir
            </button>
            <button
              className="bg-app-matte-black text-white p-4 sm:p-8"
              onClick={() => handleSetMode(ROLE_WAITER)}
            >
              Waiter
            </button>
          </div>
        </section>
      ) : changeAccountMode || !selectedUser ? (
        <section className="app-main-container flex flex-col justify-center gap-10">
          <div className="flex space-x-5">
            <p className="font-medium text-lg sm:text-2xl">Account List</p>
          </div>
          <div className="flex flex-wrap gap-4">
            {loadingUsers
              ? Array.from({ length: 12 })?.map((_, i) => (
                  <div
                    key={i}
                    className="flex gap-4 px-5 py-3 items-center rounded-md border border-app-light-grey"
                  >
                    <div className="h-10 w-10 animate-pulse bg-app-light-grey rounded-full" />
                    <div className="h-10 w-24 animate-pulse bg-app-light-grey rounded-md" />
                  </div>
                ))
              : users?.data?.map((user, i) => (
                  <button
                    key={i}
                    onClick={() => handleSelectUser(user)}
                    className="flex gap-4 px-5 py-3 items-center rounded-md border border-app-light-grey"
                  >
                    <AvatarIcon className="w-10 p-1 h-10 fill-app-matte-black rounded-full border border-app-matte-black" />
                    <p className="text-xl font-medium">{user?.name}</p>
                  </button>
                ))}
          </div>
        </section>
      ) : (
        <section className="app-small-main-container flex flex-col justify-center gap-10">
          <div className="space-y-5 flex flex-col items-center">
            <AvatarIcon className="w-32 h-32 p-2 fill-app-matte-black rounded-full border border-app-matte-black" />
            <p className="font-semibold text-base sm:text-lg">
              {selectedUser?.name}
            </p>
            <button
              className="px-10 py-3 bg-app-matte-black font-semibold rounded-md text-white"
              onClick={handleChangeAccount}
            >
              Change Account
            </button>
          </div>
          <div className="space-y-2">
            {submittingLogin ? (
              <div className="w-full border border-app-light-grey p-4 flex items-center justify-center">
                <SpinnerIcon className="h-6 w-6 fill-app-matte-black animate-spin" />
              </div>
            ) : (
              <input
                className={`w-full border ${
                  errorPin ? 'border-red-500' : 'border-app-light-grey'
                } p-4 text-center ${
                  pin ? 'text-app-matte-black' : 'text-app-grey'
                }`}
                placeholder="Enter Pin"
                value={pin}
                disabled
                type="password"
              />
            )}

            {errorPin && <p className="text-xs text-red-500">{errorPin}</p>}
          </div>
          <div className="grid grid-cols-3 justify-end gap-5">
            {pin_buttons.map((item, i) =>
              item?.value ? (
                <button
                  disabled={submittingLogin}
                  onClick={() => handleChange(item)}
                  key={i}
                  className={`flex items-center justify-center border border-app-light-grey rounded-lg py-4 transition-all ${
                    submittingLogin ? 'opacity-40' : 'opacity-100'
                  } ${
                    item?.is_delete_button
                      ? 'bg-app-matte-black'
                      : 'bg-transparent'
                  }`}
                >
                  {item?.label}
                </button>
              ) : (
                <div key={i} />
              )
            )}
          </div>
        </section>
      )}
    </div>
  );
}

export default Login;
