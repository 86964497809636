import MoneyBagIcon from "assets/icons/MoneyBagIcon";
import AppModal from "./components/AppModal";

function SuccessModal({
   title = "Success",
   subtitle = "",
   icon = (
      <MoneyBagIcon />
   )
}) {
   return (
      <AppModal>
         <div className="flex flex-col items-center space-y-4 sm:space-y-6 text-center py-2 sm:py-4">
            {icon}
            {title && (
               <p className="text-base sm:text-lg font-semibold">{title}</p>
            )}
            {subtitle && (
               <p className="text-sm sm:text-base">{subtitle}</p>
            )}
         </div>
      </AppModal>
   )
}

export default SuccessModal;