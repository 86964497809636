import React from 'react';

function KejoraButton({
  key,
  className = '',
  disabled = false,
  onClick,
  icon,
  text,
  type = 'primary',
  fullWidth = false,
  size = 'md',
}) {
  const style = {
    primary: disabled
      ? 'bg-app-light-grey text-white'
      : 'bg-app-matte-black text-white',
    secondary: disabled
      ? 'border border-app-light-grey text-app-light-grey'
      : 'border border-app-matte-black text-app-matte-black',
    danger: disabled ? 'bg-red-300 text-white' : 'bg-red-500 text-white',
    warning: disabled ? 'bg-yellow-300 text-white' : 'bg-yellow-500 text-white',
    success: disabled ? 'bg-green-300 text-white' : 'bg-green-500 text-white',
    info: disabled ? 'bg-blue-300 text-white' : 'bg-blue-500 text-white',
  };

  return (
    <button
      key={key}
      onClick={onClick}
      disabled={disabled}
      className={`px-4 ${
        size === 'xl'
          ? 'py-4'
          : size === 'lg'
          ? 'py-3 text-sm'
          : size === 'md'
          ? 'py-2 text-sm'
          : size === 'sm'
          ? 'py-2 text-xs'
          : 'py-1 text-[10px]'
      } flex rounded-md  font-semibold items-center justify-center ${className} ${
        fullWidth ? 'w-full' : ''
      } ${style[type]}`}
    >
      {icon && icon}
      <p>{text}</p>
    </button>
  );
}

export default KejoraButton;
