import React from 'react'

function DeleteIcon({
   className = "w-8 h-8 fill-white"
}) {
   return (
      <svg className={className} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M31.1667 4.25H9.91667C8.93917 4.25 8.17417 4.74583 7.66417 5.49667L0 17L7.66417 28.4892C8.17417 29.24 8.93917 29.75 9.91667 29.75H31.1667C32.725 29.75 34 28.475 34 26.9167V7.08333C34 5.525 32.725 4.25 31.1667 4.25ZM26.9167 22.0858L24.9192 24.0833L19.8333 18.9975L14.7475 24.0833L12.75 22.0858L17.8358 17L12.75 11.9142L14.7475 9.91667L19.8333 15.0025L24.9192 9.91667L26.9167 11.9142L21.8308 17L26.9167 22.0858Z" />
      </svg>
   )
}

export default DeleteIcon