import { useEffect, useState } from 'react';
import {
  calculateTimeElapsed,
  calculateTimeLeft,
  formatSecondsToTime,
} from 'utils/helpers';

function useTableTimer(session_status, session_created_dt, session_amount) {
  const [time, setTime] = useState(null);

  const FIXED = 'FIXED';
  const OPEN = 'OPEN';

  useEffect(() => {
    let timer;
    if (session_status === FIXED) {
      timer = setInterval(() => {
        const time_left = calculateTimeLeft(session_created_dt, session_amount);
        if (time_left > 0) {
          setTime(formatSecondsToTime(time_left));
        } else {
          setTime('00:00:00');
        }
      }, 1000);
    } else if (session_status === OPEN) {
      timer = setInterval(() => {
        const time_elapsed = calculateTimeElapsed(session_created_dt);
        setTime(formatSecondsToTime(time_elapsed));
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [session_amount, session_created_dt, session_status]);

  return time;
}

export default useTableTimer;
