import React from 'react';
import { STATUS } from 'utils/constants/data';
import { useLanguage } from 'utils/context/LanguageContext';

function KejoraStatus({ size = 'sm', text }) {
  const { language } = useLanguage();
  const statusColor = (text) => {
    switch (text) {
      case STATUS['en']?.FIXED?.title?.toUpperCase():
      case STATUS['en']?.OPEN?.title?.toUpperCase():
      case STATUS['en']?.OCCUPIED?.title?.toUpperCase():
        return 'bg-app-yellow';
      case STATUS['en']?.CLOSED?.title?.toUpperCase():
        return 'bg-app-red';
      case STATUS['en']?.EMPTY?.title?.toUpperCase():
        return 'bg-app-grey';
      default:
        return 'bg-app-grey';
    }
  };

  return (
    <div
      className={`text-white ${
        size === 'lg' ? 'px-4 py-2 text-base' : 'px-4 py-1 text-sm'
      } font-medium ${statusColor(text)} rounded-3xl`}
    >
      {STATUS[language][text]?.title}
    </div>
  );
}

export default KejoraStatus;
