import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import AppModal from './components/AppModal';
import useFetch from 'utils/hooks/useFetch';
import {
  ADD_BILL,
  ADD_TABLE_SESSION_FIXED,
  ADD_TABLE_SESSION_OPEN,
  GET_BILL,
} from 'utils/constants/api';
import { useAuth } from 'utils/context/AuthContext';
import KejoraButton from 'components/kejora/KejoraButton';

function StartTableModal({ data, onClose }) {
  const { user } = useAuth();
  const [tableType, setTableType] = useState('fixed');
  const [duration, setDuration] = useState('');
  const [userName, setUserName] = useState('');
  const [isSubmittingStartTable, setSubmittingStartTable] = useState(false);
  const { fetch: getBillDetails } = useFetch(GET_BILL);
  const { fetch: addBill } = useFetch(ADD_BILL);
  const { fetch: addTableSessionFixed } = useFetch(ADD_TABLE_SESSION_FIXED);
  const { fetch: addTableSessionOpen } = useFetch(ADD_TABLE_SESSION_OPEN);

  const handleStartTable = async () => {
    setSubmittingStartTable(true);
    await addBill({
      data: {
        cd: uuidv4(),
        table_cd: data?.table_cd,
        created_by: user?.cd,
        user_nm: userName,
      },
    });

    const billDetails = await getBillDetails({
      data: {
        table_cd: data?.table_cd,
      },
    });

    if (tableType === 'fixed') {
      await addTableSessionFixed({
        data: {
          table_cd: data?.table_cd,
          amount: duration,
          created_by: user?.cd,
          bill_cd: billDetails?.bill_cd,
        },
      });
      window.location.reload();
    } else {
      await addTableSessionOpen({
        data: {
          table_cd: data?.table_cd,
          created_by: user?.cd,
          bill_cd: billDetails?.bill_cd,
        },
      });
      window.location.reload();
    }
  };

  return (
    <AppModal onClose={onClose} withClose>
      <div className="space-y-8">
        <h1 className="text-2xl font-semibold text-center">Start Table</h1>
        <div className="flex space-x-2">
          <div className="text-sm sm:text-base flex-1">
            <input
              className="hidden peer"
              type="radio"
              id="fixed"
              name="fixed"
              value="fixed"
              onClick={() => setTableType('fixed')}
              checked={tableType === 'fixed'}
            />
            <label
              className={`active:scale-90 hover:scale-105 transition-all duration-200w-full peer-checked:bg-app-matte-black peer-checked:text-white flex items-center justify-center h-10 border cursor-pointer rounded-lg text-xs sm:text-sm font-bold`}
              htmlFor="fixed"
            >
              Fixed
            </label>
          </div>
          <div className="text-sm sm:text-base flex-1">
            <input
              className="hidden peer"
              type="radio"
              id="open"
              name="open"
              value="open"
              onClick={() => setTableType('open')}
              checked={tableType === 'open'}
            />
            <label
              className={`active:scale-90 hover:scale-105 transition-all duration-200w-full peer-checked:bg-app-matte-black peer-checked:text-white flex items-center justify-center h-10 border cursor-pointer rounded-lg text-xs sm:text-sm font-bold`}
              htmlFor="open"
            >
              Open
            </label>
          </div>
        </div>
        <div className="space-y-4">
          <div className="flex flex-col space-y-2">
            <label className="font-bold" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="px-3 py-2 rounded-lg"
              placeholder="Enter the customer name here"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          {tableType === 'fixed' && (
            <div className="flex flex-col space-y-2">
              <label className="font-bold" htmlFor="duration">
                Duration
              </label>
              <input
                type="number"
                id="duration"
                className="px-3 py-2 rounded-lg"
                placeholder="Enter the table duration here"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            </div>
          )}
        </div>
        <div>
          <KejoraButton
            disabled={isSubmittingStartTable}
            onClick={handleStartTable}
            text="Start Table"
            type="primary"
            fullWidth
            size="lg"
          />
        </div>
      </div>
    </AppModal>
  );
}

export default StartTableModal;
