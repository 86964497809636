import React, { useState, useCallback, useEffect } from 'react'
import AppModal from './components/AppModal';

import DeleteIcon from 'assets/icons/DeleteIcon'
import useFetch from 'utils/hooks/useFetch';

import { CHECK_USER_CREDENTIAL_SUPERVISOR } from 'utils/constants/api';

const pin_buttons = [
   {
      value: "1",
      label: "1",
   },
   {
      value: "2",
      label: "2",
   },
   {
      value: "3",
      label: "3",
   },
   {
      value: "4",
      label: "4",
   },
   {
      value: "5",
      label: "5",
   },
   {
      value: "6",
      label: "6",
   },
   {
      value: "7",
      label: "7",
   },
   {
      value: "8",
      label: "8",
   },
   {
      value: "9",
      label: "9",
   },
   {

   },
   {
      value: "0",
      label: "0",
   },
   {
      value: 'delete',
      label: (
         <DeleteIcon />
      ),
      is_delete_button: true
   },
]

function SupervisorModal({
   onClose,
   onSuccess,
   data,
}) {
   const { fetch: checkUserCredentialSupervisor } = useFetch(CHECK_USER_CREDENTIAL_SUPERVISOR)

   const [pin, setPin] = useState("")
   const [errorPin, setErrorPin] = useState(false)

   function deleteLastLetter(inputString) {
      if (inputString.length > 0) {
         return inputString.slice(0, -1);
      } else {
         return inputString;
      }
   }

   const handleChange = (item) => {
      if (pin.length < 4) {
         setErrorPin("")
         if (item?.is_delete_button) {
            setPin((prev) => {
               return deleteLastLetter(prev);
            })
         } else {
            setPin(prev => prev + item?.value)
         }
      }
   }

   const handleSuccess = useCallback(async () => {
      if (pin?.length === 4) {
         const res = await checkUserCredentialSupervisor({
            data: {
               password: pin
            }
         })
         if (res?.error) {
            setErrorPin(res?.response?.data?.data || "Wrong PIN")
            setPin("")
         } else {
            onSuccess()
            setPin("")
         }
      }
   }, [checkUserCredentialSupervisor, onSuccess, pin])

   useEffect(() => {
      handleSuccess()
   }, [handleSuccess])

   return (
      <AppModal
         withClose
         onClose={onClose}
      >
         <p className="text-center font-semibold">Supervisor Pin</p>
         <div className="space-y-2">
            <input value={pin} className="w-full border border-app-light-grey p-4 text-center" placeholder='Enter Pin' type="password" disabled />
            {errorPin && (
               <p className="text-xs text-red-500">{errorPin}</p>
            )}
         </div>
         <div className='grid grid-cols-3 justify-end gap-5'>
            {pin_buttons.map((item, i) => (
               item?.value ? (
                  <button onClick={() => handleChange(item)} key={i} className={`flex items-center justify-center border border-app-light-grey rounded-lg py-4 active:bg-app-matte-black active:text-white transition-all ${item?.is_delete_button ? "bg-app-matte-black" : "bg-transparent"}`}>{item?.label}</button>
               ) : (
                  <div></div>
               )
            ))}
         </div>
      </AppModal>
   )
}

export default SupervisorModal