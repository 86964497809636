import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';

import logo from 'assets/images/logo.png';
import AvatarIcon from 'assets/icons/AvatarIcon';
import LanguageIcon from 'assets/icons/LanguageIcon';

import { useAuth } from 'utils/context/AuthContext';
import { useLanguage } from 'utils/context/LanguageContext';
import { ROLE_KASIR } from 'utils/constants';
import { HOME_PAGE } from 'utils/constants/data';
import MenuIcon from 'assets/icons/MenuIcon';

function Navbar({ openMenu }) {
  const { pathname } = window.location;
  const { user } = useAuth();
  const { language, changeLanguage } = useLanguage();

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLanguageChange = () => {
    language === 'id' ? changeLanguage('en') : changeLanguage('id');
  };
  const handleLogout = () => {
    Cookies.remove('ut');
    window.location.reload();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !dropdownRef?.current?.contains(event.target) &&
        !buttonRef?.current?.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, buttonRef]);

  return (
    <div className={`sticky top-0 bg-white shadow-md transition-all z-30`}>
      <div
        className={`app-nav-container flex justify-between items-center gap-6 md:text-xs lg:text-base`}
      >
        <div className="flex items-center">
          <a href="/">
            <img src={logo} alt="logo" className="h-8 w-8 object-contain" />
          </a>
          <div className="hidden sm:inline-block ml-8 space-x-4 text-sm sm:text-base">
            <a
              className={`${
                pathname === '/'
                  ? 'border-b border-b-black font-medium'
                  : 'border-b border-b-white'
              } hover:border-b hover:border-b-black transition-all`}
              href="/"
            >
              Home
            </a>
            {user?.role_cd === ROLE_KASIR && (
              <a
                className={`${
                  pathname === '/bill-history'
                    ? 'border-b border-b-black font-medium'
                    : 'border-b border-b-white'
                } hover:border-b hover:border-b-black transition-all`}
                href="/bill-history"
              >
                {HOME_PAGE[language].BILL_HISTORY}
              </a>
            )}
          </div>
        </div>
        <div className="hidden relative sm:flex space-x-4">
          <button onClick={handleLanguageChange}>
            <LanguageIcon
              className={`${
                language === 'id'
                  ? 'stroke-white fill-app-matte-black w-6 h-6 stroke-2'
                  : 'stroke-app-matte-black w-6 h-6 stroke-2'
              }`}
            />
          </button>
          <div
            className="flex text-sm sm:text-base space-x-4 items-center hover:opacity-50 cursor-pointer transition-all border-2 border-app-matte-black px-3 py-2 rounded-full"
            ref={buttonRef}
            onClick={() => setDropdownOpen((prev) => !prev)}
          >
            <p>{user?.name}</p>
            <AvatarIcon />
          </div>
          {dropdownOpen && (
            <div
              ref={dropdownRef}
              className="absolute text-black top-0 mt-10 bg-white right-0 px-4 py-2 drop-shadow-xl rounded-lg"
            >
              <button className="font-medium" onClick={handleLogout}>
                Logout
              </button>
            </div>
          )}
        </div>
        <div className="sm:hidden">
          <button onClick={openMenu}>
            <MenuIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
