import React from 'react'

function MinusCircledIcon({
   className = "w-6 h-6 stroke-app-matte-black"
}) {
   return (
      <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <circle cx="12" cy="12" r="10" strokeWidth="2" />
         <path d="M7 12H17" strokeWidth="2" />
      </svg>
   )
}

export default MinusCircledIcon