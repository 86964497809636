import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { GET_BILL } from 'utils/constants/api';
import useFetch from 'utils/hooks/useFetch';
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import TableMain from 'components/table-page/TableMain';
import KejoraStatus from 'components/kejora/KejoraStatus';

function TableDetails() {
  const navigate = useNavigate();
  const params = useParams();

  const {
    data: bill,
    error: billError,
    fetch: getBillDetail,
  } = useFetch(GET_BILL);

  useEffect(() => {
    getBillDetail({
      data: {
        table_cd: params.id,
      },
    });
  }, [getBillDetail, params.id]);

  if (!bill || billError) {
    return (
      <div className="app-main-container flex flex-col items-center justify-center font-semibold">
        <p>No Order</p>
      </div>
    );
  }
  return (
    <div className="app-main-container flex flex-col items-stretch">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <button onClick={() => navigate(-1)}>
            <ArrowLeftIcon />
          </button>
          <p className="font-semibold text-lg pt-1">
            {bill?.table_nm} - {bill?.user_nm}
          </p>
        </div>
        <div className="whitespace-nowrap">
          <KejoraStatus text={bill?.bill_status} size="lg" />
        </div>
      </div>
      <TableMain bill={bill} />
    </div>
  );
}

export default TableDetails;
