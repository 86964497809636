import React from 'react';
import AppModal from './components/AppModal';
import PrintBill from 'pages/print-bill';
import KejoraButton from 'components/kejora/KejoraButton';

function PrintBillModal({
  splitBillData,
  onClose,
  handlePrint,
  printRef,
  bill_cd,
}) {
  return (
    <AppModal onClose={onClose} withClose>
      <div className="flex-1 flex flex-col items-center justify-center space-y-4 overflow-hidden">
        <div className="flex-1 overflow-auto -mr-14">
          <PrintBill
            ref={printRef}
            bill_cd={bill_cd}
            splitBillData={splitBillData}
          />
        </div>
        <KejoraButton
          onClick={handlePrint}
          text="Print Bill"
          fullWidth
          size="lg"
        />
      </div>
    </AppModal>
  );
}

export default PrintBillModal;
