import axios from 'axios';
import { io } from 'socket.io-client';
import { API_BASE_URL } from 'utils/constants/api';

const baseURL = API_BASE_URL + '/api/v1';

const api = axios.create({
  baseURL,
  withCredentials: true,
});

export const socket = io(process.env.REACT_APP_SOCKET_URL);

export default api;
