function MoneyBagIcon({
   className = "h-28 w-28 stroke-app-matte-black"
}) {
   return (
      <svg className={className} viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            d="M63 90.18V99.27M63 45.09V53.55M63 121.41C94.5 121.41 117 110.25 117 85.41C117 58.41 103.5 40.41 76.5 26.91L87.12 13.23C87.6595 12.3324 87.9522 11.3082 87.9683 10.2611C87.9844 9.21394 87.7233 8.18118 87.2117 7.26746C86.7 6.35374 85.9558 5.59153 85.0546 5.0581C84.1534 4.52467 83.1272 4.23898 82.08 4.22998H43.92C42.8728 4.23898 41.8466 4.52467 40.9454 5.0581C40.0442 5.59153 39.3 6.35374 38.7884 7.26746C38.2767 8.18118 38.0156 9.21394 38.0317 10.2611C38.0478 11.3082 38.3405 12.3324 38.88 13.23L49.5 27C22.5 40.59 9 58.59 9 85.59C9 110.25 31.5 121.41 63 121.41Z"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M54 86.04C55.0982 87.3436 56.4803 88.3782 58.0404 89.0646C59.6005 89.7511 61.2971 90.0711 63 90C65.6029 90.1775 68.1732 89.3388 70.1705 87.6604C72.1678 85.982 73.4366 83.5946 73.71 81C73.4366 78.4055 72.1678 76.0181 70.1705 74.3397C68.1732 72.6613 65.6029 71.8226 63 72C60.3972 72.1775 57.8269 71.3388 55.8296 69.6604C53.8323 67.982 52.5635 65.5946 52.29 63C52.5416 60.3964 53.8042 57.9953 55.8068 56.3125C57.8093 54.6297 60.392 53.7994 63 54C64.673 53.8977 66.3479 54.1675 67.9041 54.7899C69.4603 55.4124 70.8592 56.3722 72 57.6"
            stroke-width="5"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   )
}

export default MoneyBagIcon